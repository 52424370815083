import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { getPayouts } from '../../api/InvestorApis/apis';
import { getClaimedAmount } from '../../api/InvestorApis/investments';
import { getLiquidation } from '../../api/InvestorApis/liquidity';
import Info from '../../assets/Info.svg';
import {
  ClaimData,
  Liquidate,
  NonPaginatedPayoutResponse,
} from '../../interfaces/components';
import Graph from '../DashbordNew/Graph';
import ClaimedTokensTable from './ClaimedTokensTable';
import LiquidationTable from './LiquidationTable';
import MyTokens from './MyTokens';
import Switcher from './Switcher';
import TdWithTooltip from './TdWithTooltip';

type Tab = 'Liquidations tracker' | 'Claimed-tokens';
const LiquidationTracker: React.FC = () => {
  const [activeTab, setActiveTab] = useState<Tab>('Liquidations tracker');
  const [liquidateData, setLiquidateData] = useState<Liquidate>();
  const [nonPaginatedPayouts, setNonPaginatedPayouts] =
    useState<NonPaginatedPayoutResponse>();
  const [claimAmount, setClaimAmount] = useState<ClaimData>();
  const fetchPayouts = async (page: number) => {
    const res = await getPayouts(false, page);
    setNonPaginatedPayouts(res);
  };
  const fetchLiquidationData = async (page: number) => {
    const liquidationData = await getLiquidation(page);
    setLiquidateData(liquidationData);
  };
  const fetchClaimData = async () => {
    const claimData = await getClaimedAmount();
    setClaimAmount(claimData);
  };
  useEffect(() => {
    fetchLiquidationData(1);
    fetchClaimData();
    fetchPayouts(1);
  }, []);

  const totalClaimAmount = claimAmount?.results
    .filter((items) => !items.is_liquidated && items.is_claimed)
    .reduce((total, item) => {
      return total + item.dollar_value * 1;
    }, 0);
  console.log(totalClaimAmount);
  const handleSwitch = (tab: Tab) => {
    setActiveTab(tab);
  };
  return (
    <Box display={'flex'} flexDirection={'column'}>
      {/* <Box
        marginTop={['14px', '14px', '14px', '20px']}
        marginBottom={'20px'}
        width={'100%'}
      >
        <Text
          paddingLeft={['12px', '16px', '0px']}
          paddingRight={['12px', '16px', '20px']}
          fontSize={['20px', '22px', '24px', '36px']}
          fontWeight={700}
          fontFamily="Montserrat"
          textColor={'#1A1A1A'}
        >
          Welcome to your liquidation tracker 💰
        </Text>
      </Box> */}

      <Flex
        flexDirection={['column', 'column', 'column', 'row']}
        gap={['24px', '24px', '18px']}
        paddingLeft={['16px', '16px', '0px']}
        paddingRight={['16px', '16px', '0px']}
        paddingBottom={['18px', '18px', '18px']}
        width={'100%'}
        alignItems={'flex-start'}
        flex={1}
        borderTop={['1px solid  #F6F6F6', '1px solid  #F6F6F6', 'none']}
        overflow={'hidden'}
      >
        <Flex
          marginTop={['24px', '24px', '0px']}
          flexDirection={'column'}
          padding={['12px', '22px']}
          gap={'24px'}
          background={'#FFF'}
          borderRadius={['24px', '24px', '36px']}
          maxWidth={['100%', '100%', '100%', '518px']}
          width={['100%', '100%']}
          height={['100%', '100%', '100%', '730px']}
          boxShadow={[
            'none',
            'none',
            '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)',
          ]}
          border={'1px solid #F6F6F6'}
          flex={'1 0 0'}
        >
          <Box>
            <Flex
              flexDirection={'column'}
              gap={'10px'}
              background={'#F2F2F2'}
              padding={'14px'}
              borderRadius={'18px'}
            >
              <Box display={'flex'} alignItems={'center'} gap={'4px'}>
                <Text
                  fontSize={'16px'}
                  fontWeight={'600'}
                  fontFamily="Montserrat"
                >
                  Overall Balance
                </Text>
                <TdWithTooltip tooltip="This is the total amount of all your tokens' on-claim USD values">
                  <img src={Info} alt="Info Icon" />
                </TdWithTooltip>
              </Box>
              <Flex flexDirection={'row'} gap={'6px'}>
                <Text
                  fontSize={'36px'}
                  fontFamily="Montserrat"
                  fontWeight={700}
                  lineHeight={'normal'}
                >
                  {totalClaimAmount?.toFixed(2)}
                </Text>
                <Text
                  pt={'3px'}
                  color={'rgba(140, 140, 140, 1)'}
                  fontSize={'14px'}
                  fontWeight={500}
                  fontFamily="Montserrat"
                  lineHeight={'20px'}
                >
                  USD
                </Text>
              </Flex>
            </Flex>
          </Box>
          <Box display={'flex'} flexDirection={'column'} height={'100%'}>
            <MyTokens claimData={claimAmount} />
          </Box>
        </Flex>
        <Flex
          flex={'1 0 0'}
          padding={['none', 'none', '22px']}
          background={'rgba(255, 255, 255, 1)'}
          borderRadius={['none', 'none', '44px']}
          boxShadow={[
            'none',
            'none',
            '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)',
          ]}
          border={['none', 'none', 'rgba(255, 255, 255, 1)']}
        >
          <Graph />
        </Flex>
      </Flex>
      <Flex
        flexDirection={'column'}
        flex={'1'}
        marginTop={['15px', '15px', 'none']}
        paddingTop={['10px', '10px', 'none']}
        marginBottom={['36px', '36px', 'none']}
      >
        <Flex
          justifyContent="flex-start"
          background={'rgba(255, 255, 255, 1)'}
          borderTopRadius={'44px'}
          boxShadow={
            '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
          }
          border={'rgba(255, 255, 255, 1)'}
          padding={[
            '16px 16px 0px 16px',
            '16px 16px 0px 16px',
            '16px 16px 0px 16px',
            '22px 22px 0px 22px',
          ]}
        >
          <Switcher onSwitch={handleSwitch} activeTab={activeTab} />
        </Flex>
        {activeTab === 'Liquidations tracker' ? (
          <LiquidationTable
            liquidateData={liquidateData}
            fetchLiquidateData={fetchLiquidationData}
          />
        ) : (
          <ClaimedTokensTable payouts={nonPaginatedPayouts} />
        )}
      </Flex>
    </Box>
  );
};

export default LiquidationTracker;
