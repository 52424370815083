import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Client from './components/clients/Clients';
import LineChart from './components/common/LineChart';
import Overlay from './components/common/Overlay';
import UnauthorizedOverlay from './components/common/UnauthorizedOverlay';
import Dashboard from './components/DashbordNew/Dashboard';
import LiquadationTracker from './components/LiquadationTrackerNew/LiquadationTracker';
import LiquidationTracker2 from './components/LiquidationTracker/LiquidationTracker';
import Login from './components/login/Login';
import RestpasswordModal from './components/login/RestpasswordModal';
import Signup from './components/signup/Signup';
import ClientOverview from './components/SingleClient/ClientOverview';
import SingleClient from './components/SingleClient/SingleClient';
import UpCommingPayOut from './components/UpComminPayOut/UpCommingPayOut';
import Payouts from './payouts/Payouts';
import { TelegramProvider } from './providers/TelegramProvider';
import UserProvider from './providers/UserProvider';
import { AppStoreState, useAppStore } from './store';
import { CustomTheme } from './theme/theme';

function AuthenticatedRoutes() {
  return (
    <ChakraProvider theme={CustomTheme}>
      <BrowserRouter>
        <TelegramProvider>
          <UserProvider>
            <Overlay>
              <Routes>
                {/* <Route path="/" element={<Main />} /> */}
                <Route path="/" element={<Dashboard />} />
                {/* <Route path="/investments" element={<Investment />} /> */}
                <Route path="/clients" element={<Client />} />
                <Route path="/payouts" element={<Payouts />} />
                <Route
                  path="/liquidation-tracker"
                  element={<LiquadationTracker />}
                />
                <Route path="/*" element={<Navigate to="/" />} />
                <Route path="upcoming-payouts" element={<UpCommingPayOut />} />
                <Route
                  path="/SingleClient/:clientId"
                  element={<SingleClient />}
                />
                <Route path="Portfolio-Tracker" element={<ClientOverview />} />
                <Route path="/liquidation" element={<LiquidationTracker2 />} />

                {/* <Route path="/Dash-board" element={<Dashboard />} /> */}
                <Route path="/LineChart" element={<LineChart />} />
              </Routes>
            </Overlay>
          </UserProvider>
        </TelegramProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

function UnAuthenticatedRoutes() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <TelegramProvider>
          <UserProvider>
            <UnauthorizedOverlay>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/*" element={<Navigate to="/login" />} />
                <Route
                  path="/forgetPassword/:id/:token"
                  element={
                    <RestpasswordModal isOpen={true} onClose={() => {}} />
                  }
                />
              </Routes>
            </UnauthorizedOverlay>
          </UserProvider>
        </TelegramProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

const App = () => {
  const loginApiResponse = useAppStore(
    (state: AppStoreState) => state.loginApiResponse,
  );
  const isLoggedIn = !!loginApiResponse?.data?.access_token;
  if (isLoggedIn) {
    return <AuthenticatedRoutes />;
  }
  return <UnAuthenticatedRoutes />;
};

export default App;
