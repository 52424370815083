import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';
export const dasbord = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.03 2.77002C13.75 1.75002 11.75 1.74002 10.48 2.76002L3.92996 8.01002C2.98996 8.76002 2.41996 10.26 2.61996 11.44L3.87996 18.98C4.16996 20.67 5.73996 22 7.44996 22H18.05C19.74 22 21.34 20.64 21.63 18.97L22.89 11.43C23.07 10.26 22.5 8.76002 21.58 8.01002L15.03 2.77002ZM12.75 18.75C12.34 18.75 12 18.41 12 18V15C12 14.59 12.34 14.25 12.75 14.25C13.16 14.25 13.5 14.59 13.5 15V18C13.5 18.41 13.16 18.75 12.75 18.75Z"
      fill="currentColor"
    />
  </Icon>
);

export default dasbord;
