import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import PasswordSucessModal from './PasswordSucessModal';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../../api/InvestorApis/client';

interface RestpasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function RestpasswordModal({
  isOpen,
  onClose,
}: RestpasswordModalProps) {
  const { id, token } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('');
  const toast = useToast();
  const navigate = useNavigate();

  const handlePasswordSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevents page reload

    if (newPassword !== newPasswordConfirm) {
      toast({
        title: 'Passwords don’t match',
        status: 'error',
        isClosable: true,
        duration: 3000,
      });
      return;
    }
    if (newPassword.length < 6) {
      toast({
        title: 'Password length is less than 6 words',
        status: 'error',
        isClosable: true,
        duration: 3000,
      });
      return;
    }
    await resetPassword(id, token, newPassword);
    toast({
      title: 'Password changed successfully',
      status: 'success',
      isClosable: true,
      duration: 3000,
    });
    navigate('/');
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent
        width={['90vw', '380px', '404px', '500px']}
        borderRadius="20px"
        padding="22px"
        border="1px solid #E2E2E2"
        background="#FFF"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
      >
        <ModalBody padding="0px">
          <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} flexDir={'column'} justifyContent={'center'}>
              <Text
                color="#1A1A1A"
                fontFamily="Montserrat"
                fontSize={['16px', '28px', '20px']}
                fontWeight="700"
                alignSelf={'center'}
              >
                Reset your password
              </Text>
            </Box>

            <form style={{ marginTop: '24px' }} onSubmit={handlePasswordSubmit}>
              <VStack align="stretch">
                <FormControl>
                  <Flex flexDir={'column'} gap={'16px'}>
                    <Box>
                      <FormLabel
                        htmlFor="password"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontFamily="Montserrat"
                      >
                        New password
                      </FormLabel>
                      <Input
                        borderRadius="12px"
                        variant="filled"
                        id="password"
                        type="password"
                        placeholder="Type new password"
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </Box>
                    <Box>
                      <FormLabel
                        htmlFor="passwordConfirm"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontFamily="Montserrat"
                      >
                        Confirm new password
                      </FormLabel>
                      <Input
                        borderRadius="12px"
                        variant="filled"
                        id="passwordConfirm"
                        type="password"
                        placeholder="Re-type new password"
                        onChange={(e) => setNewPasswordConfirm(e.target.value)}
                      />
                    </Box>
                  </Flex>
                </FormControl>
              </VStack>

              <Box display="flex" justifyContent={'center'} width={'100%'}>
                <Button
                  color="white"
                  marginTop="20px"
                  width="100%"
                  borderRadius="14px"
                  background="#0FBF95"
                  fontWeight={600}
                  fontSize="14px"
                  fontFamily="Montserrat"
                  type="submit"
                  _hover={{
                    color: 'white',
                    bg: '#0FBF75',
                  }}
                >
                  Set new password
                </Button>
                <PasswordSucessModal
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                />
              </Box>
            </form>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
