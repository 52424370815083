import { Flex, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getPayouts } from '../../api/InvestorApis/apis';
import { NonPaginatedPayoutResponse } from '../../interfaces/components';
import CumulativePayoutsChart from './CumulativePayoutsChart';
import PayoutComponent from './PayoutComponent';
import RecentClaimComponent from './RecentClaimComponent';

const UpCommingPayOut: React.FC = () => {
  const toast = useToast();
  // const [payouts, setPayouts] = useState<PayoutResponse>();
  const [nonPaginatedPayouts, setNonPaginatedPayouts] =
    useState<NonPaginatedPayoutResponse>();

  const fetchPayouts = async (paginated: boolean, page: number) => {
    try {
      const res = await getPayouts(paginated, page);
      setNonPaginatedPayouts(res);
    } catch (err: any) {
      toast({
        title: 'Something went wrong while fetching payouts',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    fetchPayouts(false, 1);
  }, []);

  return (
    <>
      <Flex
        flexDirection={'column'}
        padding={[
          '0px 16px 0px 16px',
          '0px 16px 0px 16px',
          '0px 20px 0px 20px',
        ]}
        gap={'20px'}
      >
        <Flex
          flexDirection={['column', 'column', 'row']}
          gap={'20px'}
          flex={'1'}
        >
          <CumulativePayoutsChart payouts={nonPaginatedPayouts} />
          <RecentClaimComponent payouts={nonPaginatedPayouts} />
        </Flex>
        <PayoutComponent payouts={nonPaginatedPayouts} />
      </Flex>
    </>
  );
};

export default UpCommingPayOut;
