import {
  Box,
  Button,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ChevronLeft, LogOut, User } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavbarOption } from '../../interfaces/components';
import { useAppStore } from '../../store';
import Drawer from '../drawer/Drawer';
import { BottomNav } from '../header/BottomNav';
import Header from '../header/Header';

export interface OverlayProps {
  children: React.ReactNode;
}

export const navbarOptions: NavbarOption[] = [
  {
    href: '/',
    key: 'dashboard',
    text: 'Dashboard',
  },
  {
    href: '/Portfolio-Tracker',
    key: 'Portfolio Tracker',
    text: 'Portfolio Tracker',
  },
  {
    href: '/upcoming-payouts',
    key: 'payouts',
    text: 'Upcoming payouts',
  },
  {
    href: '/liquidation-tracker',
    key: 'liquidation',
    text: 'Liquidation',
  },
];

function Overlay({ children }: OverlayProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const navigate = useNavigate();
  const setLoginApiResponse = useAppStore((state) => state.setLoginApiResponse);
  const [activeView, setActiveView] = useState('payouts');

  const handleLogout = () => {
    setLoginApiResponse(null);
    navigate('/login');
  };

  const getCurrentPageTitle = () => {
    const currentRoute = navbarOptions.find(
      (navbarOption: NavbarOption) => navbarOption.href === location.pathname,
    );

    if (currentRoute) {
      return currentRoute.text;
    }

    if (
      location.pathname.includes('SingleClient') &&
      location.state?.clientName
    ) {
      return location.state.clientName;
    }

    return 'Dashboard';
  };

  useEffect(() => {
    const activeSidebarOption = navbarOptions.find(
      (navbarOption: NavbarOption) => navbarOption.href === location.pathname,
    );

    activeSidebarOption
      ? setActiveView(activeSidebarOption.key)
      : setActiveView('');
  }, [location.pathname]);

  return (
    <Box
      w="100%"
      h="100vh"
      display="flex"
      flexDirection="column"
      overflow="hidden"
    >
      <Header
        activeView={activeView}
        navbarOptions={navbarOptions}
        openDrawer={onOpen}
      />
      <Drawer
        activeView={activeView}
        navbarOptions={navbarOptions}
        isOpen={isOpen}
        onClose={onClose}
      />

      <Box
        display="flex"
        h={[
          'calc(100vh - 54px)',
          'calc(100vh - 54px)',
          'calc(100vh - 54px)',
          'calc(100vh - 65px)',
          'calc(100vh - 65px)',
        ]}
        background={['white', 'white', '#F7F7F7']}
        flexDirection="column"
      >
        <Box
          display={['flex', 'flex', 'none', 'none', 'none']}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          padding={'12px 16px'}
          fontFamily="Montserrat"
          fontWeight="600"
          fontSize="18px"
          bg="white"
          color="#1A1A1A"
          borderBottom="1px solid #E2E2E2"
        >
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <IconButton
              icon={<ChevronLeft size="24px" />}
              aria-label="Back to Dashboard"
              variant="ghost"
              color="gray.500"
              onClick={() => navigate(-1)}
              _active={{ bg: 'transparent' }}
              _hover={{ bg: 'transparent' }}
            />
            <Box>{getCurrentPageTitle()}</Box>
          </Box>
          <Popover placement="bottom-end" closeOnBlur>
            <PopoverTrigger>
              <Button
                color="gray.500"
                p="2"
                borderColor="#E2E2E2"
                borderRadius="0.8rem"
                variant="outline"
                border={'1px solid red'}
              >
                <User size="24px" />
              </Button>
            </PopoverTrigger>
            <PopoverContent
              width="150px"
              borderRadius="14px"
              boxShadow="0px 4px 14px 0px rgba(0, 0, 0, 0.35)"
            >
              <PopoverBody padding="15px">
                <Button
                  variant="link"
                  color="black"
                  fontSize="14px"
                  fontFamily="Montserrat"
                  fontWeight="500"
                  w="100%"
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap={1}
                  padding="5px"
                  cursor="pointer"
                  onClick={handleLogout}
                >
                  <LogOut size="24px" />
                  <Text>Logout</Text>
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>

        <Box
          margin="0 auto"
          width="100%"
          height="100%"
          maxWidth="1440px"
          overflowY="auto"
          css={{
            '&::-webkit-scrollbar': { width: '0px', height: '0px' },
            scrollbarWidth: 'none',
            '-ms-overflow-style': 'none',
          }}
          padding={['0px ', '0px', '20px']}
        >
          {children}
        </Box>
      </Box>
      <BottomNav />
    </Box>
  );
}

export default Overlay;
