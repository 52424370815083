import { Box, Text } from '@chakra-ui/react';
import { Chart, ChartConfiguration, ChartOptions } from 'chart.js';
import React, { useEffect, useRef, useState } from 'react';
import { getInvestmentsByDays } from '../../api/InvestorApis/investments';
import Dollar from '../../assets/Dollar.svg';
import Info from '../../assets/Info.svg';
import { GroupedInvestment } from '../../interfaces/components';
import TdWithTooltip from '../LiquadationTrackerNew/TdWithTooltip';

const Graph2: React.FC = () => {
  const [selectedTimeIndex, setSelectedTimeIndex] = useState<number>(0);
  // const [selectedDayIndex, setSelectedDayIndex] = useState<number>(6);
  const timeRanges: string[] = ['7D', '30D', '90D', 'All'];
  const chartRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  let chartInstance: Chart | null = null;

  const [days, setDays] = useState<number>(7);
  const [investmentsData, setInvestmentsData] = useState<GroupedInvestment[]>(
    [],
  );

  const fetchInvestmentsData = async () => {
    try {
      const investmentsData = await getInvestmentsByDays(days);
      setInvestmentsData(investmentsData.grouped_investments);
    } catch (error) {
      console.error('API Error:', error);
    }
  };

  useEffect(() => {
    fetchInvestmentsData();
  }, [days]);

  const processData = () => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const sortbyDate = investmentsData.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    return sortbyDate.map((item, index) => {
      // Added index parameter here
      // Create date object and adjust for UTC
      const date = new Date(item.date);
      const utcDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60000,
      );

      const day = utcDate.getDate();
      const month = months[utcDate.getMonth()];
      const label = `${day}${month}`;

      const value = item.total_market_value;

      return {
        x: index,
        y: value,
        label: label,
      };
    });
  };
  const data = processData();
  // const selectedDayChange =
  //   data[selectedDayIndex]?.y - data[selectedDayIndex - 1]?.y || 0;
  // const isSelectedDayProfit = selectedDayChange >= 0;

  const resizeChart = () => {
    if (chartInstance && containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      chartInstance.canvas.style.width = `${containerWidth}px`;
      chartInstance.canvas.style.height = '400px';
      chartInstance.resize();
    }
  };

  useEffect(() => {
    if (chartRef.current) {
      const config: ChartConfiguration = {
        type: 'line',
        data: {
          labels: data.map((item) => item.label),
          datasets: [
            {
              borderColor: '#00C950',
              borderWidth: 1,
              radius: 0,
              data: data.map((item) => item.y),
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            intersect: false,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                labelColor: () => ({
                  borderColor: '#00C950',
                  backgroundColor: '#00C950',
                }),
              },
            },
          },
          scales: {
            x: {
              ticks: {
                maxRotation: 45,
                color: '#8C8C8C',
                font: {
                  size: 12,
                  family: 'Montserrat',
                },
                callback: function (_, index) {
                  const skipLabels = data.length > 30 ? 5 : 1;
                  return index % skipLabels === 0 ? data[index].label : '';
                },
              },
              grid: {
                display: true,
                color: '#E2E2E2',
                drawBorder: false,
                drawOnChartArea: true,
                drawTicks: false,
                lineWidth: 1,
              },
            },
            y: {
              grid: {
                color: '#E2E2E2',
              },
              ticks: {
                color: '#8C8C8C',
                font: {
                  size: 12,
                  family: 'Montserrat',
                },
              },
            },
          },
          // onClick: (_event, elements) => {
          //   if (elements && elements.length > 0) {
          //     setSelectedDayIndex(elements[0].index);
          //   }
          // },
        } as ChartOptions,
      };

      if (chartInstance) {
        chartInstance.destroy();
      }
      chartInstance = new Chart(chartRef.current, config);

      resizeChart();

      window.addEventListener('resize', resizeChart);

      return () => {
        if (chartInstance) {
          chartInstance.destroy();
        }
        window.removeEventListener('resize', resizeChart);
      };
    }
  }, [data, selectedTimeIndex]);

  // Rest of the component (JSX) remains exactly the same
  return (
    <Box
      display="flex"
      padding={['20px 16px 20px 16px', '20px 16px 20px 16px', '22px ']}
      flexDirection="column"
      gap="16px"
      flex="1 0 0"
      alignSelf="stretch"
      borderRadius={['0px', '0px', '44px']}
      borderColor="#F6F6F6"
      backgroundColor="#FFF"
      justifyContent="space-between"
      width="100%"
      borderBottom={['1px solid #EFEFEF', '1px solid #EFEFEF', 'none']}
    >
      {/* Header Section */}
      <Box
        display="flex"
        alignItems="center"
        rowGap="16px"
        alignSelf="stretch"
        flexWrap="wrap"
        justifyContent={'space-between'}
      >
        <Box display="flex" alignItems="center" gap="8px">
          <Box justifyContent="left" display="flex" width="100%">
            <img
              src={Dollar}
              alt="Dollar Icon"
              style={{ marginRight: '8px' }}
            />
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap="4px"
            >
              <Text
                color="#1A1A1A"
                textAlign="center"
                fontFamily="Visby CF, sans-serif"
                fontSize="24px"
                fontWeight="600"
                lineHeight="normal"
                sx={{
                  '@media screen and (min-width: 768px)': { display: 'block' },
                  '@media screen and (max-width: 767px)': { display: 'none' },
                }}
              >
                Portfolio Value
              </Text>
              <Text
                color="#1A1A1A"
                textAlign="center"
                fontFamily="Visby CF, sans-serif"
                fontSize="24px"
                fontWeight="600"
                lineHeight="normal"
                sx={{
                  '@media screen and (min-width: 768px)': { display: 'none' },
                  '@media screen and (max-width: 767px)': { display: 'block' },
                }}
              >
                Profit/Loss History
              </Text>
              <TdWithTooltip tooltip="This is your net profit or loss on transactions per day">
                <img src={Info} alt="Info Icon" />
              </TdWithTooltip>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Selected Day Info */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignSelf="stretch"
        background="#FFF"
        borderRadius="8px"
        flexDirection={['column-reverse', 'column-reverse', 'row']}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="flex-start"
          gap="8px"
          alignSelf={['start', 'start', 'none']}
        >
          <Text
            // color={isSelectedDayProfit ? '#39D05D' : '#FF6384'}
            fontSize={['24px', '24px', '42px']}
            fontStyle="normal"
            fontWeight="600"
            margin="0"
            lineHeight="52px"
            letterSpacing="-1.26px"
            fontFamily="Montserrat"
          >
            {/* ${Math.abs(selectedDayChange).toFixed(2)} */}
          </Text>
          <Text
            color="#8C8C8C"
            textAlign="center"
            fontFamily="Montserrat"
            fontSize={['12px', '12px', '18px']}
            fontStyle="normal"
            margin="0"
            fontWeight="500"
            lineHeight="18px"
          >
            Your current position
          </Text>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignSelf={'start'}
          width={['100%', '100%', 'fit-content']}
        >
          <Box
            display="flex"
            padding="4px"
            alignItems="flex-start"
            borderRadius={['12px', '12px', '24px']}
            background="#F2F2F2"
            width={['100%', '100%', 'fit-content']}
          >
            {timeRanges.map((option, index) => (
              <Box
                key={index}
                flex={1}
                display="flex"
                padding="10px 20px"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                borderRadius={['8px', '8px', '20px']}
                background={selectedTimeIndex === index ? '#FFF' : '#F2F2F2'}
                onClick={() => {
                  const days =
                    option === 'All'
                      ? investmentsData.length
                      : parseInt(option);
                  setDays(Number(days));
                  setSelectedTimeIndex(index);
                }}
                cursor="pointer"
              >
                <Text
                  color={selectedTimeIndex === index ? '#0FBF95' : '#000'}
                  fontFamily="Montserrat"
                  fontSize={['12px', '12px', '16px']}
                  fontStyle="normal"
                  margin="0"
                  fontWeight="600"
                  lineHeight="20px"
                  whiteSpace="nowrap"
                >
                  {option}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      {/* Chart Container */}
      <Box ref={containerRef} width="100%" height="400px" position="relative">
        <canvas ref={chartRef}></canvas>
      </Box>
    </Box>
  );
};

export default Graph2;
