import { Box, FormControl, Image, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { fetchClientsInfo } from '../../api/InvestorApis/apis';
import chart from '../../assets/chart-pie.png';
import {
  ClientInfoResponse,
  ClientResultsArray,
} from '../../interfaces/components';
import Chart from './Chart';
import { CustomSelect } from './CustomSelect';
import Graph from './Graph';
import Graph2 from './Graph2';
import Investment from './Invesment'; // Make sure the import matches your new file name
import Payout from './Payout';

const Dashboard: React.FC = () => {
  const toast = useToast();
  const [clientsInfo, setClientsInfo] = useState<ClientInfoResponse>();
  const [selectedOption, setSelectedOption] =
    useState<ClientResultsArray | null>(null);

  const clientsList = {
    results: [
      { id: 1, name: 'Token overview' },
      { id: 2, name: 'Allocation' },
    ],
  };

  const fetchClientInfo = async () => {
    try {
      const res = await fetchClientsInfo();
      setClientsInfo(res);
    } catch (err: any) {
      toast({
        title: 'Something went wrong while fetching payouts',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchClientInfo();
  }, []);

  const renderContent = () => {
    if (selectedOption?.name === 'Allocation') {
      return <Chart clientInfo={clientsInfo} />;
    }
    return <Investment clientInfo={clientsInfo} />;
  };

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box
        display={'flex'}
        gap={'18px'}
        flexDirection={'column'}
        paddingBottom={['20px', '20px', 'none']}
        borderBottom={['1px solid #EFEFEF', '1px solid #EFEFEF', 'none']}
        overflow={'hidden'}
      >
        <Box
          display={'flex'}
          gap={['0px', '0px', '18px']}
          flexDir={['column', 'column', 'column', 'row']}
        >
          <Box display={'flex'} flexDirection={'column'} flex="1">
            <Box display={'flex'} flexDirection={'column'}>
              <Box
                display={'flex'}
                gap={'8px'}
                alignItems={['flex-start', 'flex-start', 'center', 'center']}
                flexDir={['column', 'column', 'row']}
                justifyContent={'space-between'}
                borderTopRadius={['0px ', '0px', '44px']}
                background={'#FFF'}
                borderBottom={'transparent'}
                boxShadow={[
                  'none',
                  'none',
                  '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)',
                ]}
                padding={['16px', '16px', '22px']}
                marginBottom={'-2px'}
              >
                <Box display={'flex'} gap={'4px'} alignItems={'center'}>
                  <Image src={chart} alt="" width={'28px'} height={'28px'} />
                  <Box display={'flex'} gap={'4px'} alignItems={'center'}>
                    <Text
                      color="#1A1A1A"
                      textAlign="center"
                      fontFamily="Montserrat"
                      fontSize="24px"
                      fontWeight="600"
                      lineHeight="normal"
                      whiteSpace={['nowrap', 'nowrap', 'nowrap', 'normal']}
                    >
                      {selectedOption?.name || 'Token Overview'}
                    </Text>
                    {/* <Image src={info} alt="" width={'20px'} height={'20px'} /> */}
                  </Box>
                </Box>
                <Box width={'fit-content'}>
                  <FormControl>
                    <CustomSelect
                      options={clientsList.results}
                      value={null}
                      placeholder="Token overview"
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      investmentToEdit={null}
                    />
                  </FormControl>
                </Box>
              </Box>
            </Box>
            <Box borderBottom={['1px solid #EFEFEF']}>{renderContent()}</Box>
          </Box>
          <Box
            display={'flex'}
            flexDir={'column'}
            justifyContent={'space-between'}
            alignItems={'flex-end'}
            borderRadius={['0px ', '0px', '44px']}
            // border={['transparent', 'transparent', '1px solid #EFEFEF']}
            borderBottom={'1px solid #EFEFEF'}
            background={'#FFF'}
            boxShadow={[
              'none',
              'none',
              '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)',
            ]}
            flex="1"
            padding={['0px 16px 24px 16px', '0px 16px 24px 16px', '22px ']}
          >
            <Graph />
          </Box>
        </Box>
        <Graph2 />
        <Payout clientInfo={clientsInfo} />
      </Box>
    </Box>
  );
};

export default Dashboard;
