import {
  Box,
  Button,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CoveyLogo from '../../assets/logo.png';
import CoveyLogoMobile from '../../assets/logoMobile.png';
import Down from '../../Icons/Down';
import Hamburger from '../../Icons/Hamburger';
import LogoutLogo from '../../Icons/LogoutLogo';
import { NavbarOption, NavbarProps } from '../../interfaces/components';
import { AppStoreState, useAppStore } from '../../store';

export default function Header({
  activeView,
  navbarOptions,
  openDrawer,
}: NavbarProps) {
  const navigate = useNavigate();
  const loginApiResponse = useAppStore(
    (state: AppStoreState) => state.loginApiResponse,
  );
  const setLoginApiResponse = useAppStore(
    (state: AppStoreState) => state.setLoginApiResponse,
  );

  return (
    <Box
      width="100%"
      h={['54px', '54px', '54px', '65px', '65px']}
      borderBottom="1px solid rgba(0, 0, 0, 0.10)"
      display={['none', 'none', 'flex']}
    >
      <Box
        margin="0 auto"
        maxWidth="1440px"
        width="inherit"
        height="inherit"
        padding={['14px 12px', '14px 12px', '14px 12px', '20px 24px']}
        display={['none', 'none', 'flex']}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display={['none', 'none', 'block', 'block']}
          cursor="pointer"
          onClick={() => {
            navigate('/');
          }}
        >
          <Image
            src={CoveyLogo}
            height={['30px', '30px', '30px', '30px', '40px']}
          />
        </Box>

        <Box
          display={['block', 'block', 'none', 'none']}
          onClick={() => {
            navigate('/');
          }}
        >
          <Image
            src={CoveyLogoMobile}
            height={['30px', '30px', '30px', '30px', '40px']}
          />
        </Box>

        <Box
          display={loginApiResponse?.data?.access_token ? 'flex' : 'none'}
          bg="transparent"
          justifyContent="center"
          alignItems="center"
          height="inherit"
          gap={[3, 3, 3, 3, 5]}
        >
          {navbarOptions.map((navbarOption: NavbarOption) => (
            <Box
              key={navbarOption.key}
              minW="100px"
              height="inherit"
              fontFamily="Montserrat"
              fontWeight="600"
              fontSize={['14px', '14px', '14px', '14px', '16px']}
              bg="transparent"
              color={activeView === navbarOption.key ? '#0FBF95' : '#8C8C8C'}
              display={['none', 'none', 'none', 'flex', 'flex']}
              alignItems="center"
              justifyContent="center"
              borderBottom={
                activeView === navbarOption.key
                  ? '2px solid #0FBF95'
                  : '2px solid transparent'
              }
              _hover={{
                color: '#0FBF95',
                borderBottom: '2px solid #0FBF95',
              }}
              transition="0.2s all"
              cursor="pointer"
              onClick={() => navigate(navbarOption.href)}
            >
              {navbarOption.text}
            </Box>
          ))}

          <Hamburger
            display={['unset', 'unset', 'unset', 'none', 'none']}
            boxSize={[6]}
            cursor="pointer"
            onClick={openDrawer}
          />
        </Box>

        {loginApiResponse?.data?.access_token ? (
          <Popover placement="bottom-end" closeOnBlur>
            <PopoverTrigger>
              <Box
                borderRadius="30px"
                outline="1px solid #0FBF95"
                display={['none', 'none', 'none', 'flex', 'flex']}
                width="150px"
                justifyContent="space-between"
                alignItems="center"
                padding="5px"
                cursor="pointer"
              >
                <Box
                  fontFamily="Montserrat"
                  fontSize="12px"
                  fontWeight="600"
                  bg="#0FBF95"
                  color="white"
                  padding="10px"
                  borderRadius="100%"
                  width="30px"
                  height="30px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  U
                </Box>
                <Text
                  fontFamily="Montserrat"
                  fontWeight="500"
                  fontSize="14px"
                  userSelect="none"
                >
                  Account
                </Text>
                <Down boxSize={6} />
              </Box>
            </PopoverTrigger>
            <PopoverContent
              width="150px"
              borderRadius="14px"
              boxShadow="0px 4px 14px 0px rgba(0, 0, 0, 0.35)"
            >
              <PopoverBody padding="15px">
                <Button
                  variant="link"
                  color="black"
                  fontSize="14px"
                  fontFamily="Montserrat"
                  fontWeight="500"
                  w="100%"
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  gap={1}
                  padding="5px"
                  cursor="pointer"
                  onClick={() => {
                    setLoginApiResponse(null);
                    navigate('/login');
                  }}
                >
                  <LogoutLogo boxSize={6} />
                  <Text>Logout</Text>
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : (
          <Stack direction="row" spacing={4}>
            <Button
              color="#000"
              fontSize=" 14px"
              fontStyle="normal"
              fontWeight=" 500"
              lineHeight="normal"
              background="rgba(0, 0, 0, 0.10)"
              borderRadius="12px"
              h={'37px'}
              onClick={() => {
                navigate('/login');
              }}
            >
              Login
            </Button>
            <Button
              color="white"
              borderRadius="12px"
              background="#5772FF"
              fontSize=" 14px"
              fontStyle="normal"
              fontWeight=" 500"
              lineHeight="normal"
              h={'37px'}
              onClick={() => {
                navigate('/signup');
              }}
            >
              Sign up
            </Button>
          </Stack>
        )}
      </Box>
    </Box>
  );
}
