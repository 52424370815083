import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export interface MessagecomProps extends IconProps {
  hasComment?: boolean;
  iconColor?: string;
}

export const Messagecom: React.FC<MessagecomProps> = ({
  hasComment,
  iconColor,
  ...props
}) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.14344 28.3358C15.0081 31.9601 22.2341 29.0141 25.6416 25.6066C31.4995 19.7487 31.4995 10.2513 25.6416 4.3934C19.7838 -1.46447 10.2863 -1.46447 4.42842 4.3934C0.875835 7.94598 -1.93867 15.0403 1.69805 20.8923C1.89944 21.2164 2.00016 21.3784 2.04941 21.5021C2.14329 21.7378 2.17088 21.9188 2.15147 22.1718C2.14128 22.3046 2.10242 22.4545 2.02468 22.7544C1.53567 24.6411 1.29117 25.5845 1.42948 26.2641C1.66953 27.4436 2.59138 28.3655 3.77094 28.6055C4.45055 28.7439 5.3939 28.4993 7.28059 28.0103C7.58039 27.9326 7.73035 27.8938 7.86266 27.8835C8.11565 27.864 8.29457 27.891 8.53049 27.9844C8.65388 28.0333 8.81706 28.1342 9.14344 28.3358ZM10.5791 14.7867C10.5791 15.6151 9.90753 16.2867 9.0791 16.2867C8.25067 16.2867 7.5791 15.6151 7.5791 14.7867C7.5791 13.9583 8.25067 13.2867 9.0791 13.2867C9.90753 13.2867 10.5791 13.9583 10.5791 14.7867ZM16.5791 14.7867C16.5791 15.6151 15.9075 16.2867 15.0791 16.2867C14.2507 16.2867 13.5791 15.6151 13.5791 14.7867C13.5791 13.9583 14.2507 13.2867 15.0791 13.2867C15.9075 13.2867 16.5791 13.9583 16.5791 14.7867ZM21.0791 16.2867C21.9075 16.2867 22.5791 15.6151 22.5791 14.7867C22.5791 13.9583 21.9075 13.2867 21.0791 13.2867C20.2507 13.2867 19.5791 13.9583 19.5791 14.7867C19.5791 15.6151 20.2507 16.2867 21.0791 16.2867Z"
      fill={(!hasComment ? '#0FBF95' : '#BFBFBF')}
    />
  </Icon>
);

export default Messagecom;
