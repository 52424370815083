import { Box, Flex, Image, Text, useToast } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import React, { useState } from 'react';
import { fetchClientsInfo, getPayouts } from '../../api/InvestorApis/apis';
import backicon from '../../assets/arrow_back_icon.png';
import ClientOverviewTab from './ClientOverviewTab';
import SwitcherContract from './switcherContract';

// Define the component without props
const ClientOverview: React.FC = () => {
  const toast = useToast();
  const {
    data: ClientInfo,
    isLoading: clientInfoLoading,
    error: clientInfoError,
  } = useQuery({
    queryKey: ['clientInfo'],
    queryFn: () => fetchClientsInfo(),
  });
  const {
    data: PayoutData,
    isLoading: payoutLoading,
    error: payoutError,
  } = useQuery({
    queryKey: ['payouts'],
    queryFn: () => getPayouts(false, 0),
  });
  const [activeTab, setActiveTab] = useState<
    'Total' | 'Recieved' | 'Value-Left'
  >('Total');

  const handleTabSwitch = (tab: 'Total' | 'Recieved' | 'Value-Left') => {
    setActiveTab(tab);
  };

  const totalInvested = ClientInfo?.results.reduce((prev, current) => {
    return prev + current.total_invested;
  }, 0);
  const totalContractValue = ClientInfo?.results.reduce((prev, curr) => {
    return prev + curr.total_contract_value * curr.total_tokens;
  }, 0);
  const totalReceivedTokenContractValue = ClientInfo?.results.reduce(
    (prev, curr) => {
      console.log(
        'Cureent = ',
        activeTab,
        curr.total_contract_value / curr.total_tokens,
      );
      return prev + curr.total_contract_value * curr.total_tokens_received;
    },
    0,
  );
  const totalLeftTokenContractValue = ClientInfo?.results.reduce(
    (prev, curr) => {
      return (
        prev +
        curr.total_contract_value *
          (curr.total_tokens - curr.total_tokens_received)
      );
    },
    0,
  );

  const displayedValue = (() => {
    switch (activeTab) {
      case 'Recieved':
        return totalReceivedTokenContractValue;
      case 'Value-Left':
        return totalLeftTokenContractValue;
      default:
        return totalContractValue;
    }
  })();
  if (clientInfoLoading || payoutLoading) return <p>Loading...</p>;
  if (clientInfoError || payoutError)
    toast({
      title: 'Something went wrong while fetching payouts',
      description: clientInfoError?.message,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  const paidPayouts = PayoutData?.results.filter((payout) => payout.paid);
  const unpaidPayouts = PayoutData?.results.filter((payout) => !payout.paid);
  const firstFourTokens = ClientInfo?.results?.slice(0, 4);
  return (
    <>
      <Flex
        flexDirection={'column'}
        marginTop={['4px', '4px', '4px', '36px']}
        marginBottom={'24px'}
        gap={['14px', '6px']}
        padding={['0px ', '0px ', '0px 20px']}
      >
        <Flex
          padding={'8px 12px'}
          justifyContent={'center'}
          alignItems={'center'}
          display={['none', 'none', 'flex']}
          borderRadius={'14px'}
          border={'1px solid #E2E2E2'}
          background={'#FFF'}
          width={'fit-content'}
          onClick={() => window.history.back()}
          cursor={'pointer'}
        >
          <Image src={backicon} alt=" " width={'24px'} height={'24px'} />
          <Text
            color={'#1A1A1A'}
            textAlign={'center'}
            fontSize={'18px'}
            fontWeight={'500'}
            lineHeight={'normal'}
            fontStyle={'normal'}
            fontFamily="Montserrat"
            onClick={() => window.history.back()}
            cursor={'pointer'}
          >
            Back
          </Text>
        </Flex>
      </Flex>
      <Flex
        gap={['0px', '14px']}
        flexDirection={'column'}
        marginBottom={['24px', '0px']}
      >
        <Flex gap={['14px']} display={['none', 'none', 'none', 'flex', 'flex']}>
          <Flex
            padding={'22px'}
            gap={'24px'}
            flexDirection={'column'}
            flex={'1 0 0'}
            borderRadius={'24px'}
            border={'1px solid #F6F6F6'}
            background={'#FFF'}
            boxShadow={[
              'none',
              'none',
              '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)',
            ]}
          >
            <Text
              color="var(--Color-Neutral-950, #1A1A1A)"
              fontFamily="Montserrat"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              Total Clients
            </Text>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
              <Text
                color="var(--Color-Neutral-950, #1A1A1A)"
                textAlign="center"
                fontFamily="Montserrat"
                fontSize="36px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                {ClientInfo?.results.length}
              </Text>
              <Box
                display={'flex'}
                flexDirection={'row'}
                gap={'10px'}
                alignItems={'center'}
              >
                <Box display="flex" alignItems="center" position="relative">
                  {firstFourTokens?.map((token, index) => {
                    return (
                      <Image
                        src={token.client.token_img}
                        alt=" "
                        width="36px"
                        height="36px"
                        position="relative"
                        zIndex={index + 1}
                        marginLeft="-6px"
                      />
                    );
                  })}
                </Box>
                <Text color={'#A6A6A6'} fontSize={'36px'} fontWeight={'500'}>
                  +
                </Text>
              </Box>
            </Flex>
          </Flex>

          <Flex
            padding={'22px'}
            gap={'24px'}
            flexDirection={'column'}
            flex={'1 0 0'}
            borderRadius={'24px'}
            border={'1px solid #F6F6F6'}
            background={'#FFF'}
            boxShadow={
              '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
            }
          >
            <Text
              color="var(--Color-Neutral-950, #1A1A1A)"
              fontFamily="Montserrat"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              Total Invested
            </Text>
            <Text
              display={'flex'}
              justifyContent={'flex-start'}
              color="var(--Color-Neutral-950, #1A1A1A)"
              textAlign="center"
              fontFamily="Montserrat"
              fontSize="36px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              $
              {totalInvested?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
          </Flex>
          <Flex
            padding={'22px'}
            gap={'24px'}
            flexDirection={'column'}
            flex={'1 0 0'}
            borderRadius={'24px'}
            border={'1px solid #F6F6F6'}
            background={'#FFF'}
            boxShadow={
              '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
            }
          >
            <Text
              color="var(--Color-Neutral-950, #1A1A1A)"
              fontFamily="Montserrat"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              Total Payouts
            </Text>
            <Text
              display={'flex'}
              justifyContent={'flex-start'}
              color="#A6A6A6"
              textAlign="center"
              fontFamily="Montserrat"
              fontSize="36px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              <span style={{ color: '#0FBF95' }}>{paidPayouts?.length}</span>/
              {unpaidPayouts?.length}
            </Text>
          </Flex>
          <Flex
            padding={'22px'}
            gap={'24px'}
            flexDirection={'column'}
            flex={'1 0 0'}
            borderRadius={'24px'}
            border={'1px solid #F6F6F6'}
            background={'#FFF'}
            boxShadow={
              '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
            }
          >
            <Text
              color="var(--Color-Neutral-950, #1A1A1A)"
              fontFamily="Montserrat"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              Total Contract Value
            </Text>

            <Text
              display={'flex'}
              justifyContent={'flex-start'}
              color="var(--Color-Neutral-950, #1A1A1A)"
              textAlign="center"
              fontFamily="Montserrat"
              fontSize="36px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              $
              {displayedValue?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
            <SwitcherContract
              activeTab={activeTab}
              onSwitch={handleTabSwitch}
            />
          </Flex>
        </Flex>
        <Flex
          padding={'0px 16px 22px 16px'}
          gap={'12px'}
          flexDirection={'column'}
          flex={'1 0 0'}
          // borderRadius={'24px'}
          // borderTop={'1px solid #F6F6F6'}
          borderBottom={'1px solid #F6F6F6'}
          background={'#FFF'}
          boxShadow={[
            'none',
            'none',
            '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)',
          ]}
          display={['flex', 'flex', 'flex', 'none', 'none']}
        >
          <Flex gap={'12px'} justifyContent={'center'}>
            <Box
              display="flex"
              flexDirection={'column'}
              padding={'14px'}
              border={'1px solid #F2F2F2'}
              borderRadius={'12px'}
              gap={'24px'}
              width={'100%'}
            >
              <Text
                color="var(--Color-Neutral-950, #1A1A1A)"
                fontFamily="Montserrat"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Total Invested
              </Text>
              <Text
                display={'flex'}
                justifyContent={'flex-start'}
                color="var(--Color-Neutral-950, #1A1A1A)"
                textAlign="center"
                fontFamily="Montserrat"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                $
                {totalInvested?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Text>
            </Box>

            <Box
              display="flex"
              flexDirection={'column'}
              padding={'14px'}
              gap={'24px'}
              border={'1px solid #F2F2F2'}
              borderRadius={'12px'}
              width={'100%'}
            >
              <Text
                color="var(--Color-Neutral-950, #1A1A1A)"
                fontFamily="Montserrat"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Total Payouts
              </Text>
              <Text
                display={'flex'}
                justifyContent={'flex-start'}
                color="#A6A6A6"
                textAlign="center"
                fontFamily="Montserrat"
                fontSize="18px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                <span style={{ color: '#0FBF95' }}>
                  {paidPayouts?.length ?? 0}
                </span>
                /{unpaidPayouts?.length}
              </Text>
            </Box>
          </Flex>
          <Flex gap={'12px'} justifyContent={'center'} flex={'1'}>
            <Box
              display="flex"
              flexDirection={'column'}
              padding={'14px'}
              justifyContent={'space-between'}
              border={'1px solid #F2F2F2'}
              borderRadius={'12px'}
              flex={'1'}
            >
              <Text
                color="var(--Color-Neutral-950, #1A1A1A)"
                fontFamily="Montserrat"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="normal"
              >
                Total Clients
              </Text>
              <Flex justifyContent={'space-between'} alignItems={'center'}>
                <Text
                  color="var(--Color-Neutral-950, #1A1A1A)"
                  textAlign="center"
                  fontFamily="Montserrat"
                  fontSize="18px"
                  fontStyle="normal"
                  fontWeight="600"
                  lineHeight="normal"
                >
                  {ClientInfo?.results.length}
                </Text>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  gap={'6px'}
                  alignItems={'center'}
                >
                  <Box display="flex" alignItems="center" position="relative">
                    {firstFourTokens?.map((token, index) => {
                      return (
                        <Image
                          src={token.client.token_img}
                          alt=" "
                          width="22px"
                          height="22px"
                          position="relative"
                          zIndex={index + 1}
                          marginLeft="-6px"
                        />
                      );
                    })}
                  </Box>
                  <Text color={'#A6A6A6'} fontSize={'18px'} fontWeight={'500'}>
                    +
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Flex>
          <Box
            display="flex"
            flexDirection={'column'}
            padding={'14px'}
            border={'1px solid #F2F2F2'}
            borderRadius={'12px'}
            gap={'8px'}
            width={'100%'}
            flex={'1'}
          >
            <Text
              color="var(--Color-Neutral-950, #1A1A1A)"
              fontFamily="Montserrat"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              Total Contract Value
            </Text>
            <Text
              display={'flex'}
              justifyContent={'flex-start'}
              color="var(--Color-Neutral-950, #1A1A1A)"
              textAlign="center"
              fontFamily="Montserrat"
              fontSize="36px"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="normal"
            >
              $
              {displayedValue?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
            <SwitcherContract
              activeTab={activeTab}
              onSwitch={handleTabSwitch}
            />
          </Box>
        </Flex>
        <ClientOverviewTab clientInfo={ClientInfo} />
      </Flex>
    </>
  );
};

export default ClientOverview;
