import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';
export const portfolio = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path
      d="M4 21.5C3.45 21.5 2.97917 21.3042 2.5875 20.9125C2.19583 20.5208 2 20.05 2 19.5V8.5C2 7.95 2.19583 7.47917 2.5875 7.0875C2.97917 6.69583 3.45 6.5 4 6.5H7C7.55228 6.5 8 6.05228 8 5.5V4.5C8 3.95 8.19583 3.47917 8.5875 3.0875C8.97917 2.69583 9.45 2.5 10 2.5H14C14.55 2.5 15.0208 2.69583 15.4125 3.0875C15.8042 3.47917 16 3.95 16 4.5V5.5C16 6.05228 16.4477 6.5 17 6.5H20C20.55 6.5 21.0208 6.69583 21.4125 7.0875C21.8042 7.47917 22 7.95 22 8.5V19.5C22 20.05 21.8042 20.5208 21.4125 20.9125C21.0208 21.3042 20.55 21.5 20 21.5H4ZM10 5.5C10 6.05228 10.4477 6.5 11 6.5H13C13.5523 6.5 14 6.05228 14 5.5C14 4.94772 13.5523 4.5 13 4.5H11C10.4477 4.5 10 4.94772 10 5.5Z"
      fill="currentColor"
    />
  </Icon>
);

export default portfolio;
