import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Radio,
  RadioGroup,
  Spinner,
  Switch,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  getClients,
  setClientAsAdvisioryToken,
} from '../../api/InvestorApis/client';
import {
  deleteInvestmentById,
  patchInvestment,
  postInvestment,
} from '../../api/InvestorApis/investments';
import Delete from '../../Icons/Delete';
import {
  ClientResultsArray,
  InvestmentResultsArray,
  ManageInvestmentModalProps,
} from '../../interfaces/components';
import { AppStoreState, useAppStore } from '../../store';
import { formatDateForInput } from '../../utils/commonFunctions';
import { CustomSelect } from './CustomSelect';
import VestingModal from './Vesting';

export default function ManageModal({
  isOpen,
  selectedClient,
  onClose,
  investmentToEdit,
  onAdded,
  onDelete,
  onValueChange,
}: ManageInvestmentModalProps & {
  onValueChange?: (value: string) => void;
}) {
  const [isVestingModalOpen, setIsVestingModalOpen] = useState(false);
  const [hasVestingPlan, setHasVestingPlan] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState<ClientResultsArray | null>(null);
  const [loading, setLoading] = useState(false);

  const [isAdvisioryToken, setIsAdvisioryToken] = useState(false);
  const [vestingMonths, setVestingMonths] = useState(0);
  const [customVestingValues, setCustomVestingValues] = useState<number[]>([]);
  const [customVestingEnabled, setCustomVestingEnabled] =
    useState<boolean>(false);

  const handleVestingChange = (value: string) => {
    setCustomVestingEnabled(value === 'custom');
  };
  const setClientsList = useAppStore(
    (state: AppStoreState) => state.setClientsList,
  );
  const clientsList = useAppStore((state: AppStoreState) => state.clientsList);
  const toast = useToast();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<InvestmentResultsArray>({
    defaultValues: {
      token_address: investmentToEdit ? investmentToEdit?.token_address : '',
      tge_percentage: investmentToEdit ? investmentToEdit?.tge_percentage : '0',
      tge: investmentToEdit ? formatDateForInput(investmentToEdit?.tge) : '',
      cliff: investmentToEdit ? investmentToEdit?.cliff : '',

      vesting: investmentToEdit ? investmentToEdit?.vesting : 0,
      //@ts-ignore
      tge_value: investmentToEdit ? investmentToEdit.tge_value : '',

      total_tokens: investmentToEdit
        ? investmentToEdit?.total_tokens
        : undefined,
      total_amount: investmentToEdit ? investmentToEdit?.total_amount : '',
    },
  });
  const totalAmount = watch('total_amount');
  useEffect(() => {
    onValueChange?.(totalAmount?.toString() || '');
  }, [totalAmount, onValueChange]);

  const vestingValue = watch('vesting');
  useEffect(() => {
    setVestingMonths(Number(vestingValue) || 0);
  }, [vestingValue]);

  useEffect(() => {
    reset({
      token_address: investmentToEdit ? investmentToEdit.token_address : '',
      tge_percentage: investmentToEdit ? investmentToEdit.tge_percentage : '',
      tge: investmentToEdit ? formatDateForInput(investmentToEdit.tge) : '',
      cliff: investmentToEdit ? investmentToEdit.cliff : '',
      vesting: investmentToEdit ? investmentToEdit.vesting : undefined,
      total_tokens: investmentToEdit
        ? investmentToEdit.total_tokens
        : undefined,
      total_amount: investmentToEdit ? investmentToEdit.total_amount : '',
      tge_value: investmentToEdit ? investmentToEdit.tge_value : '',
    });
  }, [investmentToEdit, reset]);

  const openVestingModal = () => {
    setIsVestingModalOpen(true);
  };

  const closeVestingModal = () => {
    setIsVestingModalOpen(false);
  };

  const handleVestingSubmit = () => {
    setHasVestingPlan(true);
    closeVestingModal();
  };

  const handleSwitchChange = async () => {
    setIsAdvisioryToken((prev) => !prev);
    try {
      await setClientAsAdvisioryToken(selectedClient ?? 0, !isAdvisioryToken);
      toast({
        status: 'success',
        description: 'Updated client as advisiory token sucessfully....',
        duration: 3000,
      });
    } catch (error) {
      toast({
        status: 'error',
        description: ' Error while Setting client as advisiory token....',
        duration: 3000,
      });
    }
  };

  const handleFormSubmit = async (data: InvestmentResultsArray) => {
    setLoading(true);
    try {
      if (investmentToEdit) {
        const { ...patchData } = data as any;
        if (selectedOption && selectedOption?.id) {
          patchData.client = selectedOption?.id;
        } else {
          patchData.client = investmentToEdit?.client?.id;
        }

        patchData.custom_vesting_enabled = hasVestingPlan;
        patchData.monthly_percentages = customVestingValues;
        const res = await patchInvestment(investmentToEdit?.id, patchData);
        if (res) {
          toast({
            title: 'Investment has been updated successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });

          onAdded();
          reset();
        }
      } else {
        const { tge_percentage, tge, tge_value, cliff, vesting, ...newData } =
          data as any;
        if (tge_percentage !== '') newData.tge_percentage = data.tge_percentage;
        if (tge_percentage === '') newData.tge_percentage = '0';
        if (tge !== '') newData.tge = data.tge;
        if (!vesting) newData.vesting = '1';
        else newData.vesting = vesting;
        if (cliff !== '') newData.cliff = data.cliff;
        if (isAdvisioryToken) newData.total_amount = 0;
        if (tge_value !== '') newData.tge_value = data.tge_value;
        // newData.vesting = vesting
        newData.client = selectedOption?.id;
        newData.custom_vesting_enabled = hasVestingPlan;
        newData.monthly_percentages = customVestingValues;
        console.log(newData);
        const res = await postInvestment(newData);
        if (res) {
          toast({
            title: 'Investment has been added successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });

          onAdded();
          reset();
        }
      }
    } catch (error: any) {
      toast({
        title: `Error ${investmentToEdit ? 'updating' : 'adding'} investment.`,
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchClients = async (page: number) => {
    try {
      setLoading(true);
      const res = await getClients(page);
      if (res) {
        setClientsList(res);
      }
    } catch (err: any) {
      console.log('Error While Fetch All Users ', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClients(0);
  }, []);

  useEffect(() => {
    const activeClient = clientsList?.results.filter(
      (client) => client.id === selectedClient,
    );
    setSelectedOption(selectedClient && activeClient ? activeClient[0] : null);
  }, [isOpen, clientsList, selectedClient]);

  async function handleDelete(investmentId: number) {
    try {
      setLoading(true);
      await deleteInvestmentById(investmentId);
      setLoading(false);
      toast({
        title: 'Investment has been deleted successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onDelete();
    } catch (error: any) {
      toast({
        title: `Error while deleting investment.`,
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size="xl">
      <ModalOverlay />
      <ModalContent
        width={['95vw', '400px', '546px']}
        borderRadius="20px"
        padding={['24px', '36px']}
        background="#FFF"
        boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.15)"
        maxHeight={['500px', '500px', 'unset']}
        overflowY="scroll"
      >
        <ModalBody padding="0px" position="relative">
          <CloseIcon
            top="0"
            right="0"
            position="absolute"
            boxSize={4}
            onClick={onClose}
            cursor="pointer"
          />

          {loading ? (
            <Box
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Spinner />
            </Box>
          ) : (
            <Box>
              <Text
                color="#1A1A1A"
                fontFamily="Montserrat"
                fontSize="20px"
                fontWeight="700"
                lineHeight="normal"
              >
                {investmentToEdit ? 'Edit Investment' : 'Add Investment'}
              </Text>

              <Flex justifyContent="space-between" marginTop="14px">
                <Text
                  color="#8C8C8C"
                  fontSize="16px"
                  fontWeight="500"
                  lineHeight="20px"
                  fontFamily="Montserrat"
                >
                  Set to advisory tokens
                </Text>
                <Switch
                  colorScheme="green"
                  size="lg"
                  isChecked={isAdvisioryToken}
                  onChange={handleSwitchChange}
                  sx={{
                    '& .chakra-switch__track[data-checked]': {
                      backgroundColor: '#0FBF95',
                    },
                  }}
                />
              </Flex>

              <form
                onSubmit={handleSubmit(handleFormSubmit)}
                style={{ marginTop: '24px' }}
              >
                <VStack spacing={4} align="stretch">
                  <Flex direction={{ base: 'column', md: 'row' }} gap="12px">
                    <FormControl>
                      <FormLabel
                        htmlFor="client"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        Select client
                      </FormLabel>
                      <CustomSelect
                        options={
                          selectedClient && clientsList?.results
                            ? clientsList.results.filter(
                                (client) => client.id === selectedClient,
                              )
                            : clientsList?.results || null // Change here
                        }
                        value={null}
                        placeholder="Select client"
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        investmentToEdit={investmentToEdit ?? null}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        htmlFor="token_address"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        Token address
                      </FormLabel>
                      <Input
                        disabled={true}
                        id="token_address"
                        value={selectedOption?.token_address}
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                      />
                    </FormControl>
                  </Flex>
                  <Flex direction={{ base: 'column', md: 'row' }} gap="12px">
                    <FormControl>
                      <FormLabel
                        htmlFor="total_tokens"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        Total token amount
                      </FormLabel>
                      <Input
                        id="total_tokens"
                        type="number"
                        {...register('total_tokens', {
                          required: 'Total tokens is required',
                        })}
                        placeholder="Amount"
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                      />
                    </FormControl>
                    {!isAdvisioryToken ? (
                      <FormControl>
                        <FormLabel
                          htmlFor="total_amount"
                          color="#8C8C8C"
                          fontSize="12px"
                          fontWeight={500}
                        >
                          Total invested (in USD)
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <Box
                              display="flex"
                              width="20px"
                              height="20px"
                              background="#FFF"
                              alignItems="center"
                              justifyContent="center"
                              marginTop="6px"
                              borderRadius="4px"
                              border="1px solid #E2E2E2"
                            >
                              <Text
                                fontFamily="Montserrat"
                                fontSize="12px"
                                color="#8C8C8C"
                                fontWeight="500"
                                lineHeight="20px"
                              >
                                $
                              </Text>
                            </Box>
                          </InputLeftElement>
                          <NumberInput precision={4} width={'100%'}>
                            <NumberInputField
                              id="total_amount"
                              type="number"
                              {...register('total_amount', {
                                required: 'Total amount is required',
                              })}
                              placeholder="Amount"
                              backgroundColor="#F2F2F2"
                              borderRadius="12px"
                              height="44px"
                              pl="30px"
                            />
                          </NumberInput>
                        </InputGroup>
                      </FormControl>
                    ) : (
                      <FormControl isInvalid={!!errors.tge_percentage}>
                        <FormLabel
                          htmlFor="TGE_value"
                          color="#8C8C8C"
                          fontSize="12px"
                          fontWeight={500}
                        >
                          TGE price (USD)
                        </FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <Box
                              display="flex"
                              width="20px"
                              height="20px"
                              background="#FFF"
                              alignItems="center"
                              justifyContent="center"
                              marginTop="6px"
                              borderRadius="4px"
                              border="1px solid #E2E2E2"
                            >
                              <Text
                                fontFamily="Montserrat"
                                fontSize="12px"
                                color="#8C8C8C"
                                fontWeight="500"
                                lineHeight="20px"
                              >
                                $
                              </Text>
                            </Box>
                          </InputLeftElement>
                          <NumberInput min={0} precision={8} step={0.00000001}>
                            <NumberInputField
                              id="amount"
                              defaultValue={0}
                              min={0}
                              max={100}
                              placeholder="Enter dollar amount"
                              backgroundColor="#F2F2F2"
                              borderRadius="12px"
                              height="44px"
                              pl="30px"
                              {...register('tge_value', {
                                setValueAs: (value) => {
                                  if (!value) return value;
                                  return Number(parseFloat(value).toFixed(8));
                                },
                              })}
                            />
                          </NumberInput>
                        </InputGroup>
                      </FormControl>
                    )}
                  </Flex>
                  <Flex direction={{ base: 'column', md: 'row' }} gap="12px">
                    <FormControl isInvalid={!!errors.tge_percentage}>
                      <FormLabel
                        htmlFor="tge_percentage"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        TGE percentage (optional)
                      </FormLabel>
                      <InputGroup>
                        <InputLeftElement pointerEvents="none">
                          <Box
                            display="flex"
                            width="20px"
                            height="20px"
                            background="#FFF"
                            alignItems="center"
                            justifyContent="center"
                            marginTop="6px"
                            borderRadius="4px"
                            border="1px solid #E2E2E2"
                          >
                            <Text
                              fontFamily="Montserrat"
                              fontSize="12px"
                              color="#8C8C8C"
                              fontWeight="500"
                              lineHeight="20px"
                            >
                              %
                            </Text>
                          </Box>
                        </InputLeftElement>
                        <NumberInput width={'100%'}>
                          <NumberInputField
                            id="tge_percentage"
                            defaultValue={0}
                            min={0}
                            max={100}
                            placeholder="0%"
                            backgroundColor="#F2F2F2"
                            borderRadius="12px"
                            height="44px"
                            pl="30px"
                            {...register('tge_percentage')}
                          />
                        </NumberInput>
                      </InputGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        htmlFor="tge"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        TGE date (Optional)
                      </FormLabel>
                      <Input
                        id="tge"
                        type="datetime-local"
                        {...register('tge')}
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                      />
                    </FormControl>
                  </Flex>
                  <Flex display={'flex'} flexDirection={'column'} gap={'12px'}>
                    <Text color="#8C8C8C" fontSize="12px" fontWeight={500}>
                      Vesting type
                    </Text>
                    <RadioGroup
                      display={'flex'}
                      gap={'12px'}
                      defaultValue="automatic"
                      onChange={handleVestingChange}
                    >
                      <Radio
                        value="automatic"
                        colorScheme="green"
                        size="md"
                        _checked={{ bg: '#0FBF95', borderColor: '#0FBF95' }}
                      >
                        <Text color="#8C8C8C" fontSize="14px" fontWeight={500}>
                          Automatic
                        </Text>
                      </Radio>
                      <Radio
                        value="custom"
                        colorScheme="green"
                        size="md"
                        _checked={{ bg: '#0FBF95', borderColor: '#0FBF95' }}
                      >
                        <Text color="#8C8C8C" fontSize="14px" fontWeight={500}>
                          Custom
                        </Text>
                      </Radio>
                    </RadioGroup>
                  </Flex>
                  <Flex
                    direction={{ base: 'column', md: 'row' }}
                    gap="12px"
                    marginTop={'12px !important'}
                  >
                    <FormControl>
                      <FormLabel
                        htmlFor="cliff"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        Cliff (optional)
                      </FormLabel>
                      <Input
                        id="cliff"
                        type="number"
                        {...register('cliff')}
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                        placeholder="0"
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel
                        htmlFor="vesting"
                        color="#8C8C8C"
                        fontSize="12px"
                        fontWeight={500}
                      >
                        Vesting(Optional)
                      </FormLabel>
                      <Input
                        id="vesting"
                        type="number"
                        {...register('vesting', {
                          valueAsNumber: true,
                        })}
                        backgroundColor="#F2F2F2"
                        borderRadius="12px"
                        height="44px"
                        placeholder="0"
                      />
                    </FormControl>
                  </Flex>
                  {customVestingEnabled && (
                    <Flex
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        gap={'4px'}
                      >
                        <Text color="#8C8C8C" fontSize="14px" fontWeight={500}>
                          Vesting plan
                        </Text>
                        {hasVestingPlan && (
                          <Box
                            padding={'4px 10px'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            borderRadius={' 14px'}
                            background={'#D1F5EB'}
                          >
                            <Text
                              color="#0FBF95"
                              fontSize="14px"
                              fontWeight={500}
                              display="flex"
                              alignItems="center"
                            >
                              Submitted
                            </Text>
                          </Box>
                        )}
                      </Box>
                      <Button
                        marginTop={['unset', 'unset', '24px']}
                        borderRadius="14px"
                        border="1px solid #8C8C8C"
                        fontWeight={600}
                        fontFamily="Montserrat"
                        fontSize="14px"
                        onClick={openVestingModal}
                        width={['fit-content', 'fit-content', 'fit-content']}
                        backgroundColor={'black'}
                        color={'white'}
                      >
                        {hasVestingPlan ? 'Edit' : 'Add'}
                        {/* change to Add again when modal closed */}
                      </Button>
                    </Flex>
                  )}
                  <Box
                    display="flex"
                    gap={['10px', '10px', '14px']}
                    flexDirection={['column-reverse', 'column-reverse', 'row']}
                  >
                    <Button
                      marginTop={['unset', 'unset', '24px']}
                      borderRadius="14px"
                      border="1px solid #8C8C8C"
                      fontWeight={600}
                      fontFamily="Montserrat"
                      fontSize="14px"
                      onClick={onClose}
                      width={['auto', 'auto', '50%']}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="white"
                      whiteSpace="break-spaces"
                      type="submit"
                      marginTop="24px"
                      width={['auto', 'auto', '50%']}
                      borderRadius="14px"
                      background="#0FBF95"
                      fontWeight={600}
                      fontFamily="Montserrat"
                      fontSize="14px"
                      _hover={{
                        color: 'white',
                        bg: '#0FBF75',
                      }}
                    >
                      Save changes
                    </Button>
                  </Box>
                  (
                  {investmentToEdit && (
                    <Button
                      leftIcon={<Delete boxSize={5} marginBottom={'3.4px'} />}
                      marginTop={['unset', 'unset', '24px']}
                      borderRadius="22px"
                      border="0.5px solid #E2E2E2"
                      fontWeight={500}
                      fontFamily="Montserrat"
                      fontSize="12px"
                      onClick={() => {
                        handleDelete(investmentToEdit?.id ?? 0);
                        fetchClients(1);
                        onClose();
                      }}
                      width={['100%']}
                      background={'#FFF'}
                      color={'#DC3545'}
                      alignItems={'center'}
                    >
                      Delete investment
                    </Button>
                  )}
                  )
                </VStack>
              </form>
            </Box>
          )}
        </ModalBody>
      </ModalContent>
      <VestingModal
        isOpen={isVestingModalOpen}
        onClose={closeVestingModal}
        onSubmit={handleVestingSubmit}
        months={vestingMonths}
        setCustomVestingValues={setCustomVestingValues}
      />
    </Modal>
  );
}
