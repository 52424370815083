import { Box, Button, Flex, Image, Img, Text } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import {
  getClientbyId,
  getSingleClientData,
} from '../../api/InvestorApis/client';
import backicon from '../../assets/arrow_back_icon.png';
import { Client } from '../../interfaces/components';
import ManageInvestmentModalSigle from '../investments/ManageInvestmentModalSigle';

const AddInvestment: React.FC<{ clientId: string | undefined }> = ({
  clientId,
}) => {
  const [client, setClient] = useState<Client>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const fetchClient = async (clientId: number) => {
    const client = (await getClientbyId(clientId)) as Client;
    setClient(client);
  };
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: () => getSingleClientData(parseInt(clientId ?? '1'), 1, 1),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ['singleClient'] });
    },
  });

  useEffect(() => {
    fetchClient(parseInt(clientId ?? '0'));
  }, []);

  return (
    <>
      <Flex
        flexDirection={'column'}
        marginTop={'36px'}
        marginBottom={'24px'}
        gap={['14px', '6px']}
        padding={['0px ', '0px ', '0px 20px']}
      >
        <Box
          padding={'8px 12px'}
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          borderRadius={'14px'}
          border={'1px solid #E2E2E2'}
          background={'#FFF'}
          width={'fit-content'}
          onClick={() => window.history.back()}
          cursor={'pointer'}
        >
          <Image src={backicon} alt=" " width={'24px'} height={'24px'} />
          <Text
            color={'#1A1A1A'}
            textAlign={'center'}
            fontSize={'18px'}
            fontWeight={'500'}
            lineHeight={'normal'}
            fontStyle={'normal'}
            fontFamily="Montserrat"
            onClick={() => window.history.back()}
            cursor={'pointer'}
          >
            Back
          </Text>
        </Box>
        {/* <Text
          color={'#1A1A1A'}
          textAlign={'center'}
          fontSize={['24px', '36px']}
          fontWeight={'700'}
          lineHeight={'normal'}
          fontStyle={'normal'}
          width={'fit-content'}
          fontFamily="Montserrat"
        >
          Good afternoon, Bart! 👋
        </Text> */}
      </Flex>
      <Flex
        padding={['22px']}
        maxWidth={'590px'}
        flexDirection={'column'}
        gap={'24px'}
        borderRadius={'44px'}
        border={'1px solid #F6F6F6'}
        background={'#FFF'}
        boxShadow={
          '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
        }
      >
        <Box
          display={'flex'}
          width={'100%'}
          justifyContent={'space-between'}
          gap={'12px'}
          flexDirection={'column'}
        >
          <Box display={'flex'} gap={'10px'} alignItems={'center'}>
            <Img
              src={client?.token_img || 'https://i.ibb.co/1TRDBxs/default-image.png'}
              alt=""
              width={'44px'}
              height={'44px'}
              borderRadius={'50px'}
            />
            <Text
              color={'#1A1A1A'}
              fontWeight={'700'}
              fontSize={'24px'}
              fontFamily="Montserrat"
            >
              {client?.name}
            </Text>
          </Box>
          <Text
            color="var(--Color-Neutral-500, #8C8C8C)"
            fontFamily="Montserrat"
            fontSize="18px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
          >
            This client has no investment or advisory history. Please add tokens
            to get more information on this client.
          </Text>
        </Box>
        <Button
          padding={'10px 14px'}
          display={'flex'}
          alignItems={'flex-start'}
          justifyContent={'center'}
          background={'#0FBF95'}
          borderRadius={'20px'}
          zIndex={100}
          width={'fit-content'}
          onClick={openModal}
        >
          <Text
            fontSize={'14px'}
            fontWeight={'600'}
            color={'#FFF'}
            fontFamily="Montserrat"
          >
            Add Tokens
          </Text>
        </Button>
      </Flex>
      <ManageInvestmentModalSigle
        selectedClient={parseInt(clientId ?? '1')}
        investmentToEdit={undefined}
        onAdded={() => {
          mutation.mutate();
        }}
        isOpen={isModalOpen}
        onClose={() => {
          mutation.mutate();
          closeModal();
        }}
        onDelete={() => {}}
      />
    </>
  );
};

export default AddInvestment;
