import {
  CommentResponse,
  CreateCommentRequest,
  GetInvestmentsByDays,
  GroupedInvestmentsResponse,
  InvestmentPayload,
} from '../../interfaces/components';
import axios from '../axios';

export const getInvestments = async (page: number) => {
  try {
    let endPoint = `api/investments/`;
    if (page === 0) {
      endPoint = endPoint + '?paginated=false';
    } else {
      endPoint = endPoint + `?page=${page}`;
    }

    const res = await axios.get<InvestmentPayload>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};
export const getClaimedAmount = async () => {
  try {
    const endPoint = `api/payouts/getClaimedAmount/`;
    const res = await axios.get<any>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getPayoutsByInvestmentId = async (id: number) => {
  try {
    const endPoint = `api/investments/${id}/`;
    const res = await axios.get<any>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const postInvestment = async (data: any) => {
  try {
    const endPoint = `api/investments/`;
    const res = await axios.post<Array<any>>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};

export const patchInvestment = async (id: number | string, data: any) => {
  try {
    const endPoint = `api/investments/${id}/`;
    const res = await axios.patch<Array<any>>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};

export const deleteInvestmentById = async (id: number) => {
  try {
    const endPoint = `api/investments/${id}`;
    const res = await axios.delete<any>(endPoint);
    if (!res) throw 'Something went wrong';
    return res.status;
  } catch (err: any) {
    return Promise.reject(err);
  }
};


export const getInvestmentsByDays: GetInvestmentsByDays = async (days) => {
  try {
    const endPoint = `api/investments/filter_investments_based_on_date/?days=${days}`;
    const res = await axios.get<GroupedInvestmentsResponse>(endPoint, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('getInvestmentsByDays res:', res.data);

    if (!res?.data) {
      throw new Error('No data received from API');
    }

    return res.data;
  } catch (err: any) {
    console.error('Error2:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};

export const createComment = async (
  data: CreateCommentRequest,
): Promise<CommentResponse> => {
  try {
    const endPoint = 'api/comments/';
    const response = await axios.post<CommentResponse>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response?.data) {
      throw new Error('Failed to create comment');
    }
    return response.data;
  } catch (error: any) {
    // Using 'any' as a temporary fix if AxiosError still causes issues
    console.error('Error:', error.response?.data || error.message);
    return Promise.reject(error);
  }
};

export const getComment = async (
  commentId: number,
): Promise<CommentResponse> => {
  try {
    const response = await axios.get<CommentResponse>(
      `api/comments/${commentId}/`,
    );
    return response.data;
  } catch (error: any) {
    console.error('Error:', error.response?.data || error.message);
    throw error;
  }
};

export const updateComment = async (
  commentId: number,
  content: string,
): Promise<CommentResponse> => {
  try {
    const response = await axios.patch<CommentResponse>(
      `api/comments/${commentId}/`,
      {
        content,
      },
    );
    return response.data;
  } catch (error: any) {
    console.error('Error:', error.response?.data || error.message);
    throw error;
  }
};

export const deleteComment = async (commentId: number): Promise<void> => {
  try {
    await axios.delete(`api/comments/${commentId}/`);
  } catch (error: any) {
    console.error('Error:', error.response?.data || error.message);
    throw error;
  }
};
