import { Box } from '@chakra-ui/react';
import React from 'react';

type Tab = 'Payouts' | 'Claimed-tokens' | 'Liquidations'; // Added 'Liquidations'

const Switcher: React.FC<{
  onSwitch: (tab: Tab) => void;
  activeTab: Tab;
}> = ({ onSwitch, activeTab }) => {
  const tabs: { title: string; value: Tab }[] = [
    {
      title: 'Payouts',
      value: 'Payouts',
    },
    {
      title: 'Claimed',
      value: 'Claimed-tokens',
    },
    {
      title: 'Liquidations',
      value: 'Liquidations',
    },
  ];

  return (
    <Box
      display={'flex'}
      padding={['4px']}
      alignItems={'flex-start'}
      borderRadius={['12px', '12px', '24px']}
      background={'var(--Color-Neutral-100, #F2F2F2)'}
      maxWidth={['none', 'none', 'fit-content']}
      w={['100%', '100%', 'none']}
    >
      {tabs.map((tab, index) => (
        <Box
          key={index}
          onClick={() => onSwitch(tab.value)}
          display={'flex'}
          padding={'10px 20px'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={'10px'}
          borderRadius={['8px', '8px', '20px']}
          cursor={'pointer'}
          flex={1}
          background={
            activeTab === tab.value
              ? 'var(--Color-Neutral-50, #FFF)'
              : 'transparent'
          }
          color={
            activeTab === tab.value
              ? 'var(--color-primary-500-default, #0FBF95)'
              : 'var(--Color-Neutral-500, #8C8C8C)'
          }
          fontFamily={'Montserrat'}
          fontSize={['12px', '12px', '13px', '16px']}
          fontStyle={'normal'}
          fontWeight={600}
          lineHeight={'20px'}
          whiteSpace={['normal', 'nowrap', 'nowrap', 'nowrap']}
          width={'100%'}
        >
          {tab.title}
        </Box>
      ))}
    </Box>
  );
};

export default Switcher;
