import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';
export const liquidation = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path
      d="M16.9999 21.9186C15.3899 22.1186 13.8899 21.6786 12.7199 20.8186C12.0499 20.3286 12.2099 19.2886 13.0099 19.0486C16.0199 18.1386 18.3899 15.7586 19.3099 12.7486C19.5499 11.9586 20.5899 11.7986 21.0799 12.4586C21.9299 13.6386 22.3699 15.1386 22.1699 16.7486C21.8399 19.4086 19.6599 21.5886 16.9999 21.9186Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.25 9.99C2.25 14.4 5.83 17.98 10.24 17.98C14.65 17.98 18.23 14.4 18.23 9.99C18.22 5.58 14.65 2 10.24 2C5.83 2 2.25 5.58 2.25 9.99ZM11.71 9.71C12.58 10.02 13 10.63 13 11.57C13 12.65 12.14 13.54 11.09 13.54H11V13.59C11 14 10.66 14.34 10.25 14.34C9.84 14.34 9.5 14 9.5 13.59V13.53C8.39 13.48 7.5 12.55 7.5 11.39C7.5 10.98 7.84 10.64 8.25 10.64C8.66 10.64 9 10.98 9 11.39C9 11.75 9.26 12.04 9.58 12.04H11.08C11.31 12.04 11.49 11.83 11.49 11.57C11.49 11.22 11.43 11.2 11.2 11.12L8.79 10.28C7.93 9.98 7.5 9.37 7.5 8.42C7.5 7.34 8.36 6.45 9.41 6.45H9.5V6.41C9.5 6 9.84 5.66 10.25 5.66C10.66 5.66 11 6 11 6.41V6.47C12.11 6.52 13 7.45 13 8.61C13 9.02 12.66 9.36 12.25 9.36C11.84 9.36 11.5 9.02 11.5 8.61C11.5 8.25 11.24 7.96 10.92 7.96H9.42C9.19 7.96 9.01 8.17 9.01 8.43C9 8.77 9.06 8.79 9.3 8.87L11.71 9.71Z"
      fill="currentColor"
    />
  </Icon>
);

export default liquidation;
