import { Box, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import dasbord from '../../assets/dasbord';
import liquidation from '../../assets/liquidation';
import portfolio from '../../assets/portfolio';
import upcomming from '../../assets/upcomming';

interface NavItem {
  href: string;
  label: string;
  icon: React.ElementType;
}

const navItems: NavItem[] = [
  { href: '/', label: 'Dashboard', icon: dasbord },
  { href: '/Portfolio-Tracker', label: 'Portfolio Tracker', icon: portfolio },
  { href: '/upcoming-payouts', label: 'Upcoming Payouts', icon: upcomming },
  { href: '/liquidation-tracker', label: 'Liquidation', icon: liquidation },
];

export function BottomNav() {
  const location = useLocation();
  const isVisible = useBreakpointValue({ base: true, md: true, lg: false });

  if (!isVisible) return null;

  return (
    <Box
      position="fixed"
      bottom="0"
      left="50%"
      transform="translateX(-50%)"
      bg="background"
      borderTop="1px solid"
      borderColor="gray.200"
      w="100%"
      zIndex={2}
    >
      <Flex justify="space-around" align="center" h="16">
        {navItems.map(({ href, label, icon: IconComponent }) => {
          const isSelected = location.pathname === href;
          const iconColor = isSelected ? '#0FBF95' : '#BFBFBF';

          return (
            <Link key={href} to={href}>
              <Flex
                direction="column"
                align="center"
                justify="center"
                flex="1"
                h="full"
                color={iconColor}
                _hover={{ color: '#0FBF95' }}
                transition="color 0.2s"
                paddingBottom={'13px'}
              >
                {/* Pass color dynamically */}
                <IconComponent color={iconColor} />
                <Text fontSize="xs" mt="1">
                  {label}
                </Text>
              </Flex>
            </Link>
          );
        })}
      </Flex>
    </Box>
  );
}
