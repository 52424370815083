import {
  Client,
  ClientPayload,
  ClientResponse,
  SingleClientType,
} from '../../interfaces/components';
import axios from '../axios';

export const postClient = async (data: any) => {
  try {
    const endPoint = `api/clients/`;
    const res = await axios.post<ClientResponse>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};

export const patchClient = async (
  id: number | string,
  data: Partial<ClientPayload>,
) => {
  try {
    const endPoint = `api/clients/${id}/`;
    const res = await axios.patch<Array<any>>(endPoint, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    console.error('Error:', err.response ? err.response.data : err.message);
    return Promise.reject(err);
  }
};

export const getClients = async (page: number) => {
  try {
    let endPoint = `api/clients/?page=${page}`;
    if (page === 0) {
      endPoint = `api/clients/?paginated=0`;
    }
    const res = await axios.get<ClientResponse>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const getClientbyId = async (id: number) => {
  try {
    const endPoint = `api/clients/${id}/`;
    const res = await axios.get<Client>(endPoint);
    if (!res?.data) throw new Error('Something went wrong');
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const setClientAsAdvisioryToken = async (
  id: number,
  isAdvisoryToken: boolean,
) => {
  try {
    const payload: Partial<ClientPayload> = {
      advisory_token: isAdvisoryToken,
    };
    //first we need to call the patch method and alter the advisoryToken only
    await patchClient(id, payload);
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const getSingleClientData = async (
  id: number,
  investmentPage: number = 1,
  liquidationPage: number = 1,
) => {
  try {
    // Update the endpoint to include both investment_page and liquidation_page parameters
    const endPoint = `api/clients/${id}/getAllClientData/?investment_page=${investmentPage}&liquidation_page=${liquidationPage}`;
    const res = await axios.get<SingleClientType>(endPoint);

    if (!res?.data) throw new Error('Something went wrong');

    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};

export const deleteClientById = async (id: number) => {
  try {
    const endPoint = `api/clients/${id}`;
    const res = await axios.delete<any>(endPoint);
    if (!res) throw 'Something went wrong';
    return res.status;
  } catch (err: any) {
    return Promise.reject(err);
  }
};
export const sendForgetPasswordEmail = async (email: string) => {
  try {
    const endPoint = `api/user/forget/`;
    const res = await axios.post<any>(
      endPoint,
      { email: email },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    if (!res) throw 'Something went wrong';
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};
export const resetPassword = async (
  id: string | undefined,
  token: string | undefined,
  password: string,
) => {
  try {
    const endPoint = `api/user/reset/`;
    const res = await axios.post<any>(
      endPoint,
      { uid: id, token: token, new_password: password },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
    if (!res) throw 'Something went wrong';
    return res.data;
  } catch (err: any) {
    return Promise.reject(err);
  }
};
