import { Icon, IconProps } from '@chakra-ui/icons';
import React from 'react';

export const upcomming = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.56 2C4.92 2 2.75 4.17 2.75 7.81V16.18C2.75 19.83 4.92 22 8.56 22H16.93C20.57 22 22.74 19.83 22.74 16.19V7.81C22.75 4.17 20.58 2 16.94 2H8.56ZM10.28 10.98L12 12.7V6.51C12 6.1 12.34 5.76 12.75 5.76C13.16 5.76 13.5 6.1 13.5 6.51V12.7L15.22 10.98C15.51 10.69 15.99 10.69 16.28 10.98C16.57 11.27 16.57 11.75 16.28 12.04L13.28 15.04C13.21 15.11 13.13 15.16 13.04 15.2C12.95 15.24 12.85 15.26 12.75 15.26C12.65 15.26 12.56 15.24 12.46 15.2C12.37 15.16 12.29 15.11 12.22 15.04L9.22 12.04C8.93 11.75 8.93 11.28 9.22 10.98C9.51 10.69 9.99 10.69 10.28 10.98ZM12.75 18.23C10.63 18.23 8.52 17.89 6.51 17.22C6.12 17.09 5.91 16.66 6.04 16.27C6.17 15.88 6.59 15.66 6.99 15.8C10.71 17.04 14.8 17.04 18.52 15.8C18.91 15.67 19.34 15.88 19.47 16.27C19.59 16.67 19.38 17.09 18.99 17.22C16.98 17.89 14.87 18.23 12.75 18.23Z"
      fill="currentColor"
    />
  </Icon>
);

export default upcomming;
