import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Collapse,
  Flex,
  HStack,
  IconButton,
  Image,
  Img,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { ClientInfoResponse } from '../../interfaces/components';
import Switcher, { Tab } from './SwitcherToken';

const Investment: React.FC<{ clientInfo: ClientInfoResponse | undefined }> = ({
  clientInfo,
}) => {
  const [expandedRowIndex, setExpandedRowIndex] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState<Tab>('Investments');
  const rowsPerPage = 4;

  const filteredData = useMemo(() => {
    if (!clientInfo?.results) return [];

    switch (activeTab) {
      case 'Investments':
        return clientInfo.results.filter(
          (item) => Number(item.total_invested) > 0,
        );
      case 'Advisory':
        return clientInfo.results.filter(
          (item) =>
            Number(item.total_invested) === 0 && Number(item.total_tokens) > 0,
        );
      default:
        return clientInfo.results;
    }
  }, [clientInfo?.results, activeTab]);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage,
  );

  const openRow = (index: number) => {
    setExpandedRowIndex(expandedRowIndex === index ? null : index);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const renderValue = (data: any, active_tab: string) => {
    console.log('Tpta; = ', data.total_contract_value);
    if (active_tab === 'Investments') {
      return data.profit < 0 ? `-$${Math.abs(data.profit)}` : `$${data.profit}`;
    } else if (active_tab === 'Advisory') {
      return `$${data.total_contract_value * data.total_tokens}`;
    }
    return null; // Or some default value if needed
  };
  const renderPageNumbers = () => {
    const items = [];

    const renderPageNumber = (page: number) => (
      <Box
        key={page}
        background={page === currentPage ? '#0FBF95' : 'white'}
        borderRadius="full"
        padding={'0px 6px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Text
          fontWeight="500"
          fontSize="18px"
          lineHeight="20px"
          color={page === currentPage ? 'white' : '#8C8C8C'}
          cursor="pointer"
          onClick={() => handlePageChange(page)}
        >
          {page}
        </Text>
      </Box>
    );

    items.push(renderPageNumber(1));
    if (totalPages > 1) {
      items.push(renderPageNumber(2));
    }

    if (currentPage > 3 && totalPages > 3) {
      items.push(
        <Text key="dots1" fontSize="18px" color="#8C8C8C">
          ...
        </Text>,
      );
    }

    if (currentPage > 2 && currentPage < totalPages) {
      items.push(renderPageNumber(currentPage));
    }

    if (currentPage < totalPages - 1 && totalPages > 3) {
      items.push(
        <Text key="dots2" fontSize="18px" color="#8C8C8C">
          ...
        </Text>,
      );
    }

    if (totalPages > 2) {
      items.push(renderPageNumber(totalPages));
    }

    return items;
  };

  return (
    <>
      <Box
        borderBottomRadius={'44px'}
        background={'#FFF'}
        boxShadow={[
          'none',
          'none',
          '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)',
        ]}
        padding={[
          '0px 16px 16px 16px',
          '0px 16px 16px 16px',
          '0px 22px 22px 22px',
        ]}
        height={['fit-content', 'fit-content', '575px']}
        borderTop={'transparent'}
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          marginBottom="20px"
          flexShrink={0}
        >
          <Switcher onSwitch={setActiveTab} activeTab={activeTab} />
        </Box>
        <Box position="relative" flex="1" minHeight={0}>
          <Box position="absolute" inset={0} overflow="auto">
            <Table
              variant="unstyled"
              display={['none', 'none', 'none', 'table']}
              minWidth={activeTab === 'Investments' ? '520px' : '1000px'}
            >
              <Thead mb="16px" display={'block'}>
                <Tr
                  borderRadius={'10px'}
                  border={'solid 0.5px #E2E2E2'}
                  background={'#F6F6F6'}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Th
                    border="none"
                    fontFamily="Montserrat"
                    color={'#1A1A1A'}
                    fontSize={'13px'}
                    fontWeight={'600'}
                    lineHeight={'20px'}
                    padding={'6px 14px'}
                    flex="1"
                    textTransform="none"
                  >
                    Client
                  </Th>
                  {activeTab === 'Investments' ? (
                    <>
                      <Th
                        border="none"
                        fontFamily="Montserrat"
                        color={'#1A1A1A'}
                        fontSize={'13px'}
                        fontWeight={'600'}
                        lineHeight={'20px'}
                        padding={'6px 14px'}
                        flex="1"
                        textAlign={'center'}
                        textTransform="none"
                      >
                        Profit/Loss
                      </Th>
                      <Th
                        border="none"
                        fontFamily="Montserrat"
                        color={'#1A1A1A'}
                        fontSize={'13px'}
                        fontWeight={'600'}
                        lineHeight={'20px'}
                        padding={'6px 14px'}
                        flex="1"
                        textAlign={'right'}
                        whiteSpace={'nowrap'}
                        textTransform="none"
                      >
                        Tokens Claimed
                      </Th>
                    </>
                  ) : (
                    <>
                      <Th
                        border="none"
                        fontFamily="Montserrat"
                        color={'#1A1A1A'}
                        fontSize={'13px'}
                        fontWeight={'600'}
                        lineHeight={'20px'}
                        padding={'6px 14px'}
                        flex="1"
                        textAlign={'left'}
                        whiteSpace={'nowrap'}
                        textTransform="none"
                      >
                        Tokens Received
                      </Th>
                      <Th
                        border="none"
                        fontFamily="Montserrat"
                        color={'#1A1A1A'}
                        fontSize={'13px'}
                        fontWeight={'600'}
                        lineHeight={'20px'}
                        padding={'6px 14px'}
                        flex="1"
                        textAlign={'left'}
                        whiteSpace={'nowrap'}
                        textTransform="none"
                      >
                        Total Contract Value
                      </Th>
                      <Th
                        border="none"
                        fontFamily="Montserrat"
                        color={'#1A1A1A'}
                        fontSize={'13px'}
                        fontWeight={'600'}
                        lineHeight={'20px'}
                        padding={'6px 14px'}
                        flex="1"
                        textAlign={'left'}
                        whiteSpace={'nowrap'}
                        textTransform="none"
                      >
                        Value Received
                      </Th>
                      <Th
                        border="none"
                        fontFamily="Montserrat"
                        color={'#1A1A1A'}
                        fontSize={'13px'}
                        fontWeight={'600'}
                        lineHeight={'20px'}
                        padding={'6px 14px'}
                        flex="1"
                        textAlign={'left'}
                        whiteSpace={'nowrap'}
                        textTransform="none"
                      >
                        Value Left
                      </Th>
                    </>
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {paginatedData?.map((data, index) => (
                  <Tr
                    key={index}
                    borderBottom={
                      (index + 1) % 4 === 0
                        ? 'transparent'
                        : '0.5px solid #F2F2F2'
                    }
                    mb={(index + 1) % 4 === 0 ? '0px' : '20px'}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Td border="none" padding="14px" flex="1">
                      <Flex alignItems={'center'} gap={'8px'}>
                        <Img
                          src={
                            data.client.token_img ??
                            'https://i.ibb.co/1TRDBxs/default-image.png'
                          }
                          alt=""
                          height={'36px'}
                          width={'36px'}
                        />
                        <Text
                          fontSize={'16px'}
                          fontWeight={'600'}
                          lineHeight={'normal'}
                          fontFamily="Montserrat"
                          color={'#1A1A1A'}
                        >
                          {data.client.name}
                        </Text>
                      </Flex>
                    </Td>
                    {activeTab === 'Investments' ? (
                      <>
                        <Td
                          border="none"
                          padding="14px"
                          flex="1"
                          textAlign="center"
                          color={
                            data.profit < 0 && activeTab === 'Investments'
                              ? 'red.500'
                              : 'black'
                          }
                        >
                          {renderValue(data, activeTab)}
                        </Td>

                        <Td border="none" padding="14px" flex="1">
                          <Box display={'flex'} justifyContent={'flex-end'}>
                            {data.total_tokens_received} of {data.total_tokens}
                          </Box>
                        </Td>
                      </>
                    ) : (
                      <>
                        <Td border="none" padding="14px" flex="1">
                          <Box display={'flex'} justifyContent={'flex-start'}>
                            {data.total_tokens_received} of {data.total_tokens}
                          </Box>
                        </Td>
                        <Td
                          border="none"
                          padding="14px"
                          flex="1"
                          textAlign="left"
                          color="black"
                        >
                          $
                          {(
                            data.total_contract_value * data.total_tokens
                          ).toLocaleString('en-US', {
                            minimumFractionDigits: 4,
                            maximumFractionDigits: 4,
                          })}
                        </Td>
                        <Td
                          border="none"
                          padding="14px"
                          flex="1"
                          textAlign="left"
                          color="black"
                        >
                          $
                          {(
                            data.total_tokens_received *
                            data.total_contract_value
                          ).toLocaleString('en-US', {
                            minimumFractionDigits: 4,
                            maximumFractionDigits: 4,
                          })}
                        </Td>
                        <Td
                          border="none"
                          padding="14px"
                          flex="1"
                          textAlign="left"
                          color="black"
                        >
                          $
                          {(
                            (data.total_tokens - data.total_tokens_received) *
                            data.total_contract_value
                          ).toLocaleString('en-US', {
                            minimumFractionDigits: 4,
                            maximumFractionDigits: 4,
                          })}
                        </Td>
                      </>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>

          <Table
            variant="unstyled"
            display={['table', 'table', 'table', 'none']}
          >
            <Thead mb="16px" display={'block'}>
              <Tr
                borderRadius={'10px'}
                border={'solid 0.5px #E2E2E2'}
                background={'#F6F6F6'}
                display="flex"
                justifyContent="space-between"
              >
                <Th
                  border="none"
                  fontFamily="Montserrat"
                  color={'#1A1A1A'}
                  fontSize={'13px'}
                  fontWeight={'600'}
                  lineHeight={'20px'}
                  padding={'6px 14px'}
                  flex="1"
                  position="relative"
                  textTransform="none"
                >
                  Client
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: '15%',
                      bottom: '15%',
                      width: '1px',
                      background: '#E2E2E2',
                    }}
                  />
                </Th>
                {activeTab === 'Investments' ? (
                  <Th
                    border="none"
                    fontFamily="Montserrat"
                    color={'#1A1A1A'}
                    fontSize={'13px'}
                    fontWeight={'600'}
                    lineHeight={'20px'}
                    padding={'6px 14px'}
                    flex="1"
                    textAlign={'end'}
                    whiteSpace={'nowrap'}
                    textTransform="none"
                  >
                    Tokens Claimed
                  </Th>
                ) : (
                  <Th
                    border="none"
                    fontFamily="Montserrat"
                    color={'#1A1A1A'}
                    fontSize={'13px'}
                    fontWeight={'600'}
                    lineHeight={'20px'}
                    padding={'6px 14px'}
                    flex="1"
                    textAlign={'right'}
                    whiteSpace={'nowrap'}
                    textTransform="none"
                  >
                    Tokens Received
                  </Th>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {paginatedData?.map((data, index) => (
                <Tr
                  key={index}
                  borderRadius={['12px', '12px', '36px']}
                  background="#F6F6F6"
                  mb={(index + 1) % 4 === 0 ? '0px' : '20px'}
                  display="flex"
                  flexDirection="column"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    padding="20px 20px 14px 20px"
                    onClick={() => openRow(index)}
                    cursor="pointer"
                    style={{ position: 'relative', zIndex: 1 }}
                  >
                    <Flex alignItems={'center'} gap={'8px'}>
                      <Image
                        src={
                          data.client.token_img ??
                          'https://i.ibb.co/1TRDBxs/default-image.png'
                        }
                        alt=""
                        height={'36px'}
                        width={'36px'}
                      />
                      <Text
                        fontSize={'13px'}
                        fontWeight={'600'}
                        lineHeight={'normal'}
                        fontFamily="Montserrat"
                        color={'#1A1A1A'}
                      >
                        {data.client.name}
                      </Text>
                    </Flex>
                    {activeTab === 'Investments' ? (
                      <Flex alignItems="center" gap={2}>
                        <Text fontSize={'13px'}>
                          {data.total_tokens_received} of {data.total_tokens}
                        </Text>
                        <ChevronDownIcon
                          color="#8C8C8C"
                          boxSize={6}
                          transform={
                            expandedRowIndex === index
                              ? 'rotate(180deg)'
                              : 'unset'
                          }
                          transition="0.2s all"
                        />
                      </Flex>
                    ) : (
                      <Flex alignItems="center" gap={2}>
                        <Text fontSize={'13px'}>
                          {data.total_tokens_received} of {data.total_tokens}
                        </Text>
                        <ChevronDownIcon
                          color="#8C8C8C"
                          boxSize={6}
                          transform={
                            expandedRowIndex === index
                              ? 'rotate(180deg)'
                              : 'unset'
                          }
                          transition="0.2s all"
                        />
                      </Flex>
                    )}
                  </Box>
                  <Collapse in={expandedRowIndex === index} animateOpacity>
                    {activeTab === 'Investments' ? (
                      <Box padding="0px 50px 14px 20px">
                        <Flex justifyContent="space-between" mb="10px">
                          <Text
                            color="#8C8C8C"
                            fontSize="13px"
                            fontWeight="500"
                          >
                            Profit/Loss
                          </Text>
                          <Text
                            display={'flex'}
                            color={data.profit < 0 ? 'red.500' : '#8C8C8C'}
                            fontSize={'12px'}
                            fontWeight={'600'}
                            lineHeight={'20px'}
                            alignSelf={'flex-end'}
                            fontFamily="Montserrat"
                          >
                            {renderValue(data, activeTab)}
                          </Text>
                        </Flex>
                      </Box>
                    ) : (
                      <Box padding="0px 50px 14px 20px">
                        <Flex justifyContent="space-between" mb="10px">
                          <Text
                            color="#8C8C8C"
                            fontSize="13px"
                            fontWeight="500"
                          >
                            Total Contract Value
                          </Text>
                          <Text
                            display={'flex'}
                            color={'#8C8C8C'}
                            fontSize={'12px'}
                            fontWeight={'600'}
                            lineHeight={'20px'}
                            alignSelf={'flex-end'}
                            fontFamily="Montserrat"
                          >
                            $
                            {(
                              data.total_contract_value * data.total_tokens
                            ).toLocaleString('en-US', {
                              minimumFractionDigits: 4,
                              maximumFractionDigits: 4,
                            })}
                          </Text>
                        </Flex>
                        <Flex justifyContent="space-between" mb="10px">
                          <Text
                            color="#8C8C8C"
                            fontSize="13px"
                            fontWeight="500"
                          >
                            Value Received
                          </Text>
                          <Text
                            display={'flex'}
                            color={'#8C8C8C'}
                            fontSize={'12px'}
                            fontWeight={'600'}
                            lineHeight={'20px'}
                            alignSelf={'flex-end'}
                            fontFamily="Montserrat"
                          >
                            $
                            {(
                              data.total_tokens_received *
                              data.total_contract_value
                            ).toLocaleString('en-US', {
                              minimumFractionDigits: 4,
                              maximumFractionDigits: 4,
                            })}
                          </Text>
                        </Flex>
                        <Flex justifyContent="space-between" mb="10px">
                          <Text
                            color="#8C8C8C"
                            fontSize="13px"
                            fontWeight="500"
                          >
                            Value Left
                          </Text>
                          <Text
                            display={'flex'}
                            color="#8C8C8C"
                            fontSize={'12px'}
                            fontWeight={'600'}
                            lineHeight={'20px'}
                            alignSelf={'flex-end'}
                            fontFamily="Montserrat"
                          >
                            $ $
                            {(
                              (data.total_tokens - data.total_tokens_received) *
                              data.total_contract_value
                            ).toLocaleString('en-US', {
                              minimumFractionDigits: 4,
                              maximumFractionDigits: 4,
                            })}
                          </Text>
                        </Flex>
                      </Box>
                    )}
                  </Collapse>
                </Tr>
              ))}
            </Tbody>
          </Table>

          <HStack
            spacing="6px"
            justifyContent={'flex-start'}
            display={['none', 'none', 'none', 'flex', 'flex']}
            mt={['20px']}
            position="absolute"
            bottom={6}
            left={0}
            bg="white"
            zIndex={1}
            py={2}
          >
            <IconButton
              aria-label="Previous page"
              icon={<ChevronLeftIcon height={'24px'} width={'24px'} />}
              onClick={() => handlePageChange(currentPage - 1)}
              isDisabled={currentPage <= 1}
              variant="ghost"
              _hover={{
                bg: 'gray.100',
                borderRadius: 'full',
              }}
              transition="all 0.2s"
            />

            {renderPageNumbers()}

            <IconButton
              aria-label="Next page"
              icon={<ChevronRightIcon height={'24px'} width={'24px'} />}
              onClick={() => handlePageChange(currentPage + 1)}
              isDisabled={currentPage >= totalPages}
              variant="ghost"
              _hover={{
                bg: 'gray.100',
                borderRadius: 'full',
              }}
              transition="all 0.2s"
            />
          </HStack>
        </Box>
      </Box>
      <Flex
        justifyContent={'center'}
        paddingBottom={['12px', '12px', '12px', '0px']}
      >
        <HStack
          spacing="6px"
          justifyContent={'center'}
          display={['flex', 'flex', 'flex', 'none', 'none']}
          padding={'16px'}
          borderRadius={'44px'}
          border={'1px solid #EFEFEF'}
          background={'#FFF'}
          boxShadow={
            '0px 6px 10px 0px rgba(177, 177, 177, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.02)'
          }
          width={'fit-content'}
          alignSelf={'center'}
          mt={['0px', '0px', '20px']}
        >
          <IconButton
            aria-label="Previous page"
            icon={<ChevronLeftIcon height={'24px'} width={'24px'} />}
            onClick={() => handlePageChange(currentPage - 1)}
            isDisabled={currentPage <= 1}
            variant="ghost"
            _hover={{
              bg: 'gray.100',
              borderRadius: 'full',
            }}
            transition="all 0.2s"
          />

          {renderPageNumbers()}

          <IconButton
            aria-label="Next page"
            icon={<ChevronRightIcon height={'24px'} width={'24px'} />}
            onClick={() => handlePageChange(currentPage + 1)}
            isDisabled={currentPage >= totalPages}
            variant="ghost"
            _hover={{
              bg: 'gray.100',
              borderRadius: 'full',
            }}
            transition="all 0.2s"
          />
        </HStack>
      </Flex>
    </>
  );
};

export default Investment;
