import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getClaimedAmount } from '../../api/InvestorApis/investments';
import { getLiquidation } from '../../api/InvestorApis/liquidity';
import {
  ClaimData,
  Liquidate,
  NonPaginatedPayoutResponse,
} from '../../interfaces/components';

import { getPayouts } from '../../api/InvestorApis/apis';
import ClaimedTokensTable from './ClaimedTokensTable';
import LiquidationTable from './LiquidationTable';
import MyTokens from './MyTokens';
import Switcher from './Switcher';

const LiquidationTracker2: React.FC = () => {
  const [activeTab, setActiveTab] = useState<
    'liquidation-tracker' | 'available-tokens'
  >('liquidation-tracker');
  const [liquidateData, setLiquidateData] = useState<Liquidate>();
  const [nonPaginatedPayouts, setNonPaginatedPayouts] =
    useState<NonPaginatedPayoutResponse>();
  const [claimAmount, setClaimAmount] = useState<ClaimData>();
  const toast = useToast();
  const fetchPayouts = async (paginated: boolean, page: number) => {
    try {
      const res = await getPayouts(true, page);
      if (!paginated) {
        await getPayouts(false, 1);
        setNonPaginatedPayouts(res);
      }
    } catch (err: any) {
      toast({
        title: 'Something went wrong while fetching payouts',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const fetchLiquidationData = async (page: number) => {
    const liquidationData = await getLiquidation(page);
    setLiquidateData(liquidationData);
  };
  const fetchClaimData = async () => {
    const claimData = await getClaimedAmount();
    setClaimAmount(claimData);
  };
  useEffect(() => {
    fetchLiquidationData(1);
    fetchClaimData();
    fetchPayouts(false, 1);
  }, []);

  const totalClaimAmount = claimAmount?.results
    .filter((items) => !items.is_liquidated && items.is_claimed)
    .reduce((total, item) => {
      return total + item.price * (item.amount - item.tokens_liquidated);
    }, 0);

  return (
    <Box display={'flex'} flexDirection={'column'}>
      {/* <Box
        marginTop={['14px', '14px', '14px', '24px', '20px']}
        marginBottom={'36px'}
        width={'100%'}
      >
        <Text
          paddingLeft={['12px', '16px', '20px']}
          paddingRight={['12px', '16px', '20px']}
          fontSize={['20px', '22px', '24px', '36px']}
          fontWeight={700}
          fontFamily="Montserrat"
          textColor={'#1A1A1A'}
        >
          Good afternoon, Bart! 👋
        </Text>
      </Box> */}
      <Flex
        flexDirection={['column', 'column', 'row']}
        gap={['36px', '36px', '18px']}
        paddingLeft={['0px', '0px', '20px']}
        paddingRight={['0px', '0px', '20px']}
        paddingBottom={['0px', '0px', '40px']}
        maxWidth={'100%'}
        alignItems={'flex-start'}
      >
        <Flex
          flexDirection={'column'}
          padding={['12px', '16px']}
          gap={'24px'}
          background={'#FFF'}
          borderRadius={'20px'}
          width={'100%'}
          maxWidth={['auto', 'auto', 'auto', '380px']}
        >
          <Box>
            <Text fontSize="24px" fontWeight={600} fontFamily="Montserrat">
              Overview
            </Text>
            <Flex
              flexDirection={'column'}
              gap={'10px'}
              background={'#F2F2F2'}
              padding={'14px'}
              borderRadius={'18px'}
            >
              <Text
                fontSize={'16px'}
                fontWeight={'600'}
                fontFamily="Montserrat"
              >
                Balance
              </Text>
              <Flex flexDirection={'row'} gap={'6px'}>
                <Text
                  fontSize={'36px'}
                  fontFamily="Montserrat"
                  fontWeight={700}
                  lineHeight={'normal'}
                >
                  {totalClaimAmount?.toFixed(2)}
                  {/* <Animation value={totalClaimAmount?.toFixed(2)} /> */}
                </Text>
                <Text
                  pt={'3px'}
                  color={'rgba(140, 140, 140, 1)'}
                  fontSize={'14px'}
                  fontWeight={500}
                  fontFamily="Montserrat"
                  lineHeight={'20px'}
                >
                  USD
                </Text>
              </Flex>
            </Flex>
          </Box>
          <Box display={'flex'} flexDirection={'column'}>
            <MyTokens claimData={claimAmount} />
          </Box>
        </Flex>
        <Flex
          flexDirection={'column'}
          padding={[
            '12px 0px 12px 0px ',
            '12px 0px 12px 0px ',
            '12px 0px 12px 0px ',
            '16px',
          ]}
          gap={'24px'}
          background={'rgba(255, 255, 255, 1)'}
          width={['100%', '100%', '100%', '100%']}
          border={'1px solid var(--Color-Neutral-200, #E2E2E2)'}
          borderRadius={'20px'}
        >
          <Switcher activeTab={activeTab} onSwitch={setActiveTab} />
          {activeTab === 'liquidation-tracker' ? (
            <LiquidationTable
              liquidateData={liquidateData}
              fetchLiquidateData={fetchLiquidationData}
            />
          ) : (
            <ClaimedTokensTable payouts={nonPaginatedPayouts} />
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default LiquidationTracker2;
